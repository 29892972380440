import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import { DateFormatType } from "../../model/dateFormatType";
import { DatesParamsNoms } from "../../model/dateParamsNoms";

import { YDate } from "../../utils/date";

export function getNextPage(link: string) {
  if (!link) {
    return null;
  }
  const links = link.split(",");
  if (!links.length) {
    return null;
  }

  const nextLink = links.find((link) => link.includes("next"));
  if (!nextLink) {
    return null;
  }
  const linkTab = nextLink.replace("<", "").replace(">", "").split(";");
  const page = getParameterByName("page", linkTab[0]);
  return page;
}

function getParameterByName(name: string, url: string): number | null {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return 0;
  const pageNumber = decodeURIComponent(results[2].replace(/\+/g, " "));
  return +pageNumber;
}

export const blobToBase64 = async (
  blob: Blob
): Promise<string | ArrayBuffer | null> => {
  const promise64: Promise<string | ArrayBuffer | null> = new Promise(
    (resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    }
  );
  return promise64;
};

export function formatStringWithoutDiacritics(valeur: string): string {
  const valeurSansApostrophe = valeur.replace(/'/g, " ");
  const valeurSansDiacritics = valeurSansApostrophe.replace(
    /[`~!@#$%^&*()_|+\=?;:'',.<>\{\}\[\]\\\/]/gi,
    ""
  );
  const valeurFormate = valeurSansDiacritics
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return valeurFormate.toUpperCase();
}

export function checkEan(eanCode: string) {
  // Check if only digits

  var ValidChars = "0123456789";

  for (let i = 0; i < eanCode.length; i++) {
    const digit = eanCode.charAt(i);

    if (ValidChars.indexOf(digit) == -1) {
      return false;
    }
  }

  // Add five 0 if the code has only 8 digits
  if (eanCode.length !== 13) {
    return false;
  }

  // Get the check number

  const originalCheck = eanCode.substring(eanCode.length - 1);

  eanCode = eanCode.substring(0, eanCode.length - 1);

  // Add even numbers together

  let even =
    Number(eanCode.charAt(1)) +
    Number(eanCode.charAt(3)) +
    Number(eanCode.charAt(5)) +
    Number(eanCode.charAt(7)) +
    Number(eanCode.charAt(9)) +
    Number(eanCode.charAt(11));

  // Multiply this result by 3

  even *= 3;

  // Add odd numbers together

  const odd =
    Number(eanCode.charAt(0)) +
    Number(eanCode.charAt(2)) +
    Number(eanCode.charAt(4)) +
    Number(eanCode.charAt(6)) +
    Number(eanCode.charAt(8)) +
    Number(eanCode.charAt(10));

  // Add two totals together

  const total = even + odd;

  // Calculate the checksum

  // Divide total by 10 and store the remainder

  let checksum = total % 10;

  // If result is not 0 then take away 10

  if (checksum != 0) {
    checksum = 10 - checksum;
  }

  // Return the result

  if (checksum != +originalCheck) {
    return false;
  }
  return true;
}

/*
 * cleanObject
 * retire les propriétés null ou undefined de l'objet
 */
export const cleanObject = (obj: any) => {
  for (var propName in obj) {
    const condition =
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName].length === 0;

    if (condition) {
      delete obj[propName];
    }
  }

  return obj;
};

/*
 * toUrlWithParams
 * transforme des params de recherche sous form d'objet en query string
 */
export const toUrlWithParams = (url: string, params: any): string => {
  const fullUrl = new URL(url);

  fullUrl.search = toSearchParams(params);

  return fullUrl.toString();
};

/*
 * toSearchParams
 * transforme des params de recherche sous form d'objet en query string
 */
export const toSearchParams = (params: any): string => {
  if (!params) {
    return "";
  }

  if (!params?.sort) {
    return new URLSearchParams(params).toString();
  }

  const { sort, ...othersParams } = params;
  const searchParams = new URLSearchParams(othersParams).toString();
  const sortParam = toSortParam(sort, othersParams);
  return searchParams + sortParam;
};

/*
 * toSortParam
 * construction du query param pour trier les resultats
 * on passe par exemple : sort: 'date,desc'
 */
const toSortParam = (sort: string, othersParams: any) => {
  const esperluette = Object.keys(othersParams).length === 0 ? "" : "&";

  return sort ? `${esperluette}sort=${sort}` : "";
};

/*
 * toRangeDateParams
 * Convertis un formulaire contenant un champs de formulaire d'un calendar primeng
 * en un formulaire avec deux champs au format attendu par l'api yvidya
 * retourne le formValue avec les deux params au format ISO
 * et sans le param range
 */
export const toRangeDateParams = (
  formValue: any,
  datesParamsNoms: DatesParamsNoms,
  format: DateFormatType = DateFormatType.iso
) => {
  const rangeDatesValue = formValue[datesParamsNoms.range];

  const isValidRangeDates =
    rangeDatesValue &&
    dateIsValid(rangeDatesValue[0]) &&
    dateIsValid(rangeDatesValue[1]);

  if (isValidRangeDates) {
    formValue = addRangeDatesParams(formValue, datesParamsNoms, format);
  }

  delete formValue[datesParamsNoms.range];
  return formValue;
};

const addRangeDatesParams = (
  formValue: any,
  datesParamsNoms: DatesParamsNoms,
  format: DateFormatType
) => {
  const [debut, fin] = formValue[datesParamsNoms.range];

  const dateFinTimestamp = fin?.setHours(23, 59, 59, 999);
  const dateFin = new Date(dateFinTimestamp);

  return {
    ...formValue,
    [datesParamsNoms.debut]: formatRangeDatesParams(debut, format),
    [datesParamsNoms.fin]: formatRangeDatesParams(dateFin, format),
  };
};

const formatRangeDatesParams = (controlValue: any, format: DateFormatType) => {
  return format === DateFormatType.iso
    ? controlValue.toISOString()
    : new YDate(controlValue).toShortFormat();
};

export const dateIsValid = (date: Date): boolean => {
  return date instanceof Date;
};

/*
 * getRoundedAmount
 *
 */
export function getRoundedAmount(amount: number): number {
  return Math.round((amount + Number.EPSILON) * 100) / 100;
}

/*
 * decimalCount
 *
 */
export function getDecimalCount(num: number): number {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
}

/*
 * roundToTwoDecimals
 *
 */
export function getRoundedAmountToTwoDecimals(
  decimalCount: number,
  roundedAmount: number
): string {
  if (decimalCount == 2) {
    return `${roundedAmount}`;
  }

  const roundedAmountStr = String(roundedAmount);

  const preparedRoundedAmountStr =
    decimalCount === 0 ? `${roundedAmountStr}.00` : `${roundedAmountStr}0`;
  return preparedRoundedAmountStr;
}

/**
 * Detecte si le paramètre reçu est un objet
 * @param object
 * @returns
 */
export const isObject = (object: Object) => {
  return object != null && typeof object === "object";
};

/**
 * handleCatchError
 * @param error
 * @returns
 */
export const handleCatchError = (error: HttpErrorResponse) => {
  let errorMessage: string;
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error("An error occurred:", error.error.message);
    errorMessage = `An error occured: ${error.error.message}`;
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(
      `Backend returned code ${error.status}, ` + `body was: ${error.error}`
    );
    errorMessage =
      `Backend returned code ${error.status}, ` + `body was: ${error.error}`;
  }
  // Return an observable with a user-facing error message.
  return throwError(error);
};

export const sortDescending = (array: any[], key: string) => {
  return array.sort((a, b) => (a[key] > b[key] ? -1 : a[key] > b[key] ? 1 : 0));
};

/**
 * calculeTVA
 * @param ttc
 * @param ht
 * @param tp
 * @returns {number}
 */
export const calculeTVA = (ttc: number, ht: number, tp: number): number => {
  const tva = ttc - ht - tp;
  return Math.round((tva + Number.EPSILON) * 100) / 100;
};
