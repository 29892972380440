import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactAdresseFilter',
  pure: false
})
export class ContactAdresseFilterPipe implements PipeTransform {

  transform(items: any[], field : string): any {

    if (!items || !field) {
      return ;
    }

    const adresse =  items.find(item => item);
    if(!adresse){
      return;
    }
    return adresse[field];

  }

}
