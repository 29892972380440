import { HttpResponse } from "@angular/common/http";
import { Component, Injectable, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { StorageMap } from "@ngx-pwa/local-storage";
import { BehaviorSubject, Subject } from "rxjs";
import {
  filter,
  finalize,
  first,
  map,
  switchMap,
  take,
  takeUntil,
} from "rxjs/operators";
import { Client } from "src/app/shared/model/client";
import { Entite } from "src/app/shared/model/entite";
import { ClientService } from "src/app/shared/service/client/client.service";
import { environment } from "src/environments/environment";

declare const checkEmail: any;
@Component({
  selector: "app-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.scss"],
})
@Injectable()
export class ClientsComponent implements OnInit, OnDestroy {
  sortOrder = "nom,prenom,asc";
  nbClientSearch: number = 0;
  moveTable = true;
  loader = false;
  infiniteScroll: boolean;
  listClient$: BehaviorSubject<any> = new BehaviorSubject([]);
  clients: Client[] = [];
  paramsSearch: string;
  inputEnter = false;
  customSearch = true;

  client: Client;
  firstLaunch = true;
  labelEnvAndApiClientExterne: string = "";
  nextLink = "";
  private destroy$: Subject<any> = new Subject();

  constructor(
    private clientService: ClientService,
    public dialog: MatDialog,
    private localStorage: StorageMap
  ) {}

  // Init
  ngOnInit() {
    window.addEventListener("scroll", this.infinityScroll, true);

    this.clientService.loader$
      .pipe(
        map((loader) => (this.loader = loader)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.clientService.client$
      .pipe(
        map((client: Client) => (this.client = client)),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.localStorage
      .get("entite")
      .pipe(
        take(1),
        map((entite) => entite as Entite),
        map(this.getLicence),
        switchMap(this.initForm),

        switchMap(this.getListClient)
      )
      .subscribe();
  }

  getLicence = (entite: Entite) => {
    const apiClientExterne = entite.apiClientExterne;
    this.labelEnvAndApiClientExterne = `api : ${apiClientExterne}, env : ${environment.apiUrl} `;
    this.clientService.licence = apiClientExterne
      ? `SYNALIA${apiClientExterne}`
      : entite.numeroLicence;
  };

  getListClient = () => {
    return this.clientService.listClient.pipe(
      map((listClient) => (this.clients = listClient)),
      takeUntil(this.destroy$)
    );
  };

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    window.removeEventListener("scroll", this.infinityScroll, true);
  }

  initForm = () => {
    return this.clientService.searchText$.pipe(
      // Call api only clientQuery is not empty
      filter(this.filterForm),
      // switch to new search observable each time the term changes
      switchMap(this.getClientApi),
      takeUntil(this.destroy$),
      finalize(() => this.clientService.loader$.next(false))
    );
  };

  filterForm = () => {
    if (this.firstLaunch) {
      this.firstLaunch = false;
      return false;
    }
    const clientQuery = this.clientService.clientQuery$.getValue();
    if (clientQuery.length > 0 || this.customSearch) {
      return true;
    }
    if (this.nbClientSearch > 0) {
      this.clientService.listClient.next([]);
    }
    this.nbClientSearch = 0;

    return false;
  };

  infinityScroll = (event: any) => {
    // visible height + pixel scrolled >= total height
    if (
      event.target.scrollTop > 0 &&
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
    ) {
      if (!this.nextLink.length || this.loader) {
        return;
      }

      this.customSearch = true;
      this.loader = true;
      this.infiniteScroll = true;
      return this.getClientApi()
        .pipe(
          first(),
          map((response: Client[]) =>
            this.clientService.listClient.next(response)
          )
        )
        .subscribe();
    }
  };

  handleChangeClient(data: any) {
    if (!data.client) {
      return;
    }

    const client: Client = data.client;

    this.clientService.client$.next(client);
  }

  handleChangeMoveTable(data: any) {
    this.moveTable = data ? data.value : false;
  }

  handleChangeSortOrder(data: any) {
    if (!data || data.sortOrder === this.sortOrder) {
      return;
    }
    this.sortOrder = data.sortOrder;
    this.clientService.loader$.next(true);
    this.getClientApi().subscribe((response: Client[]) =>
      this.clientService.listClient.next(response)
    );
  }

  reload(data?: any) {
    const emptyClient = {} as Client;
    this.clientService.client$.next(emptyClient);
    this.clientService.listClient.next([]);
    this.nextLink = "";
    this.clientService.loader$.next(true);
    this.customSearch = (data && data.custom) || false;
    const searchString = data && data.searchString;
    this.clientService.searchText$.next(searchString);
  }

  searchClient(data: any) {
    const emptyClient = {} as Client;
    this.clientService.client$.next(emptyClient);
    this.clientService.listClient.next([]);
    this.firstLaunch = false;
    const custom = data.customSearch;
    const searchString = data.searchString;
    this.paramsSearch = this.formatSearchString(data.searchString);

    this.reload({ searchString, custom });
  }

  formatSearchString(data: string) {
    const searchArraySplit = data.split(" ");
    const stringMulti: string[] = [];
    const newArray = searchArraySplit.map((item: string) => {
      if (+item) {
        return (item = this.searchInteger(item));
      }
      if (checkEmail(item)) {
        return (item = this.validateEmail(item));
      }
      stringMulti.push(item);
    });
    let searchString = "";

    const multiString = stringMulti.join(" ");
    if (multiString.length && multiString.match(/[a-z]/i)) {
      searchString += `nom=${multiString}`;
    }
    const newString = newArray.join("&");

    searchString += newString;
    return searchString;
  }

  searchInteger(term: string) {
    if (term.length > 10) {
      return `carteFidelite=${term}`;
    }

    if (term.length === 10) {
      return `telephone=${term}`;
    }

    return `codePostal=${term}`;
  }

  validateEmail(term: string) {
    return `email=${term}`;
  }

  searchClientInput(term: string) {
    return this.clientService.searchClientInput(
      this.customSearch ? term : this.paramsSearch,
      this.sortOrder,
      this.infiniteScroll ? this.nextLink : ""
    );
  }
  getClientApi = () => {
    const search = this.clientService.searchText$.getValue();

    return this.searchClientInput(search).pipe(
      first(),
      map((res: HttpResponse<Client[]>) => {
        return this.getClients(res);
      })
    );
  };

  formatLink(link: string) {
    if (!link) {
      return "";
    }
    const links = link.split(",");
    if (!links.length) {
      return "";
    }

    const nextLink = links.find((link) => link.includes("next"));
    if (!nextLink) {
      return "";
    }
    const linkTab = nextLink.replace("<", "").replace(">", "").split(";");
    return linkTab[0];
  }

  getClients(res: HttpResponse<Client[]>) {
    // Nb client
    const countclient = res.headers.get("X-Total-Count");
    this.nbClientSearch = countclient ? +countclient : 0;
    // Recupère la page suivante
    const link = res.headers.get("Link");
    this.nextLink = this.formatLink(link || "");
    // Recupère les clients
    const clients = res.body || [];

    const nextClients = [...this.clients, ...clients];
    this.clientService.loader$.next(false);
    this.clientService.listClient.next(nextClients);
    return nextClients;
  }
}
