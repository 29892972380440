import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { EnrichToken } from "../../model/enrichToken";
import { Permission } from "../../model/permission";
import { Observable, throwError } from "rxjs";
import { catchError, shareReplay } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private http: HttpClient) {}

  public postEnrichToken(entite: string): Observable<EnrichToken> {
    return this.http
      .post<EnrichToken>(
        `${environment.apiUrl}/api/enrichtoken?key=entite&value=${entite}`,
        {}
      )
      .pipe(
        catchError((err) => throwError(err)),
        shareReplay(3)
      );
  }

  public getPermissions(): Observable<Permission[]> {
    return this.http
      .get<Permission[]>(`${environment.apiUrl}/api/permission-yshop`)
      .pipe(
        catchError((err) => throwError(err)),
        shareReplay(3)
      );
  }
}
