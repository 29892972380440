import { Component, Injectable, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageMap } from "@ngx-pwa/local-storage";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
@Injectable()
export class HomeComponent implements OnInit {
  searchString = "";
  vente = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: StorageMap
  ) {}

  ngOnInit() {
    console.log("je passe");
    console.log(this.router.url);
    if (this.router.url.includes("searchString")) {
      this.searchString = this.route.snapshot.queryParams.searchString;
    }
    console.log("je passe encore ");
    if (this.router.url.includes("version")) {
      console.log(this.router.url);
      console.log("je passe encore encore");
      console.log(this.route.snapshot.queryParams);

      const version = this.route.snapshot.queryParams.version;
      const now = new Date();
      const date = now.toISOString().split("T")[0]; // Format YYYY-MM-DD
      const time = now.toTimeString().split(" ")[0].slice(0, 5); // Format HH:MM
      const storageKey = `versionWD_${date}_${time.replace(":", "-")}`;

      console.log("storageKey", storageKey);

      localStorage.setItem(storageKey, version);

      this.localStorage
        .set("versionWD", this.route.snapshot.queryParams.version)
        .subscribe();
    }
    if (this.router.url.includes("vente")) {
      this.vente =
        this.route.snapshot.queryParams.vente === "false" ? false : true;
    }
    if (this.router.url.includes("clientUuid")) {
      const clientUuid = (this.searchString =
        this.route.snapshot.queryParams.clientUuid);
      return this.router.navigate([`/clients/${clientUuid}/edition`]);
    }
    this.router.navigate([`/clients`], {
      queryParams: { searchString: this.searchString, vente: this.vente },
    });
  }
}
