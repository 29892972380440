import { Pays } from './pays'

export class PaysMock extends Pays {
  constructor() {
    super({
      id: 0,
      iso2: 'FR',
      iso3: '',
      nom: 'France',
    })
  }
}
