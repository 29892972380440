import { Pipe, PipeTransform } from "@angular/core";
import { Adresse } from "../../model/adresse";
import { ClientService } from "../../service/client/client.service";

@Pipe({
  name: "ContactAdresseNpai",
  pure: false,
})
export class ContactAdresseNpaiPipe implements PipeTransform {
  constructor() {}
  transform(adresses: Adresse[]): any {
    if (!adresses) {
      return false;
    }
    const adresse = adresses[0];
    if (!adresse) {
      return;
    }
    return adresse.npai ? true : false;
  }
}
