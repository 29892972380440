import { Pipe, PipeTransform } from '@angular/core';
import { Pays } from '../../model/pays';

@Pipe({
  name: 'contactNomPays'
})
export class ContactNomPaysPipe implements PipeTransform {

  transform(pays: Pays[], iso2: string): any {
    if (!pays) {
      return '';
    }
    const currentPays = pays.find(item => item.iso2 === iso2);
    if (!currentPays) {
      return '';
    }
    return currentPays.nom;
  }

}
