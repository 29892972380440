import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/api";

import { BehaviorSubject } from "rxjs";
import { ClientService } from "src/app/shared/service/client/client.service";
declare const checkEmail: any;
declare const cleanObject: any;
declare const replaceAll: any;
declare var formatStringWithoutDiacritics: any;
@Component({
  selector: "app-clients-recherche",
  templateUrl: "./clients-recherche.component.html",
  styleUrls: ["./clients-recherche.component.scss"],
})
@Injectable()
export class ClientsRechercheComponent implements OnInit {
  @Output() searchClient: EventEmitter<any> = new EventEmitter();
  @Output() handleChangeMoveTable: EventEmitter<any> = new EventEmitter();
  @Input() nbClient: number;
  displayCustomSearch = true;
  formSearchClient: UntypedFormGroup;
  clientQuery: string;
  loader: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.clientService.formSearchClient.reset();
    this.formSearchClient = this.clientService.formSearchClient;
    this.clientService.clientQuery$.subscribe((item) => {
      this.clientQuery = item;
    });
    const searchString = this.router.url.includes("searchString");
    if (searchString) {
      this.clientQuery = this.route.snapshot.queryParams.searchString;

      const customSearch = false;
      if (this.clientQuery) {
        this.#clientQueryInit(customSearch);
      }
    }
  }
  setClientQuery() {
    this.clientService.clientQuery$.next(this.clientQuery);
  }

  searchInteger(term: string) {
    if (term.length > 10) {
      return this.clientService.formSearchClient.controls.carteFidelite.patchValue(
        term
      );
    }
    if (term.length === 10) {
      return this.clientService.formSearchClient.controls.telephone.patchValue(
        term
      );
    }
    return this.clientService.formSearchClient.controls.codePostal.patchValue(
      term
    );
  }

  codePostalMaxSize() {
    const cp = this.formSearchClient.controls.codePostal.value;
    if (cp && cp.length > 5) {
      this.formSearchClient.controls.codePostal.setValue(cp);
      return false;
    }
    return true;
  }

  isNumber(str: string) {
    var pattern = /^\d+$/;
    return pattern.test(str); // returns a boolean
  }

  async telephoneRegex(event: any) {
    if (!event.target && !event.target.value) {
      return;
    }
    const tel = event.target.value;
    const isNumber = this.isNumber(tel);
    if (tel.length && !isNumber) {
      const telFormatOnlyNumber = tel.replace(/[^\d+$]/g, "");
      return this.formSearchClient.controls.telephone.setValue(
        telFormatOnlyNumber
      );
    }
  }

  setForm(clientQuery: string) {
    if (!clientQuery) {
      return;
    }
    const clientQuerySplit = clientQuery.split(" ");
    const stringMulti: string[] = [];
    clientQuerySplit.map((item: string) => {
      if (+item) {
        return this.searchInteger(item);
      }
      if (checkEmail(item)) {
        return this.clientService.formSearchClient.controls.email.patchValue(
          item
        );
      }
      stringMulti.push(item);
    });
    const multiString = stringMulti.join(" ");
    if (multiString.length && multiString.match(/[a-z]/i)) {
      return this.formSearchClient.controls.nom.patchValue(multiString);
    }
  }

  closeCustomSearch(event: any) {
    event.preventDefault();
    this.handleChangeMoveTable.emit({ value: false });
    this.displayCustomSearch = false;
  }

  customSearch() {
    if (!this.formValid()) {
      return;
    }
    let searchString = "";
    const formObject = cleanObject(this.clientService.formSearchClient.value);
    Object.keys(formObject).map((index) => {
      const value = this.formSearchClient.controls[index].value;
      const valeurFormate =
        index !== "email" ? formatStringWithoutDiacritics(value) : value;
      this.formSearchClient.controls[index].patchValue(valeurFormate);
      const valeurFormatesansEtoile = replaceAll("*", "%", valeurFormate);
      const encodevalue = encodeURI(valeurFormatesansEtoile);
      searchString += `&${index}=${encodevalue}`;
    });

    this.searchClient.emit({ searchString, customSearch: true });
  }

  handleChangeCustomSearch() {
    this.displayCustomSearch = !this.displayCustomSearch;
    this.handleChangeMoveTable.emit({ value: true });
    if (this.displayCustomSearch) {
      this.setForm(this.clientQuery);
    }
  }

  search() {
    this.formatQuerystring();
    this.setForm(this.clientQuery);
    this.searchClient.emit({
      searchString: this.clientQuery,
      customSearch: false,
    });
  }

  formValid() {
    if (this.clientService.formSearchClient.invalid) {
      this.clientService.formSearchClient.markAllAsTouched();
      return false;
    }
    return true;
  }

  formatQuerystring() {
    const query = this.clientQuery.split(" ");
    const queryStringFormat = query.map((item) => {
      if (!checkEmail(item)) {
        return formatStringWithoutDiacritics(item);
      }
      return item;
    });
    this.clientQuery = queryStringFormat.join(" ");
  }

  /**
   * FormatString
   * Supprime ou remplace les valeurs Diacritics
   */
  formatString(event: any, field: string) {
    if (!(event.target && event.target.value)) {
      return;
    }
    const valeur = event.target.value.trim();
    const valeurFormate = formatStringWithoutDiacritics(valeur);
    this.formSearchClient.controls[field].setValue(valeurFormate);
  }

  openToaster(severity: string, summary: string) {
    return this.messageService.add({ severity, summary });
  }

  #clientQueryInit = (customSearch: boolean) => {
    if (this.clientQuery.length < 3) {
      const toasterLabel =
        "Pour optimiser la recherche veuillez saisir 3 caractères minimum";
      this.openToaster("warn", toasterLabel);
      return this.setForm(this.clientQuery);
    }
    this.clientService.clientQuery$.next(this.clientQuery);
    this.setForm(this.clientQuery);
    const params = replaceAll("*", "%", this.clientQuery);
    const encodevalue = encodeURI(params);
    this.searchClient.emit({
      searchString: encodevalue,
      customSearch,
    });
  };
}
