import { HttpClient } from '@angular/common/http'
import { LoaderSource } from 'src/environments/source.enum'

import { InMemoryPaysLoader } from '../adapters/infra/inmemory/inmemory-pays.loader'
import { YvidyaPaysLoader } from '../adapters/infra/real/yvidya-pays.loader'

import { Pays } from '../domain/pays/pays'
import { PaysLoader } from '../domain/pays/pays.loader'
import { PaysMock } from '../domain/pays/pays.mock'
import { ConfigSources } from './config.sources'

export class ConfigDIFactory {
  static paysLoader(http: HttpClient): PaysLoader {
    switch (ConfigSources.pays) {
      case LoaderSource.yvidya:
        return new YvidyaPaysLoader(http)
      default:
        const unpays: Pays = new PaysMock()
        return new InMemoryPaysLoader([unpays])
    }
  }
}
