import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EMPTY, Observable, Subject, fromEvent } from "rxjs";
import {
  catchError,
  debounceTime,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { FormSearchHistoClient } from "src/app/clients/services/form-search-histo-client.service";
import { searchHistoClient } from "src/app/clients/services/search-histo-client.service";
import { Client } from "src/app/shared/model/client";
import { HAL } from "src/app/shared/model/hal";
import { HistoClient } from "src/app/shared/model/histoClient";

@Component({
  selector: "app-client-histo-dialog",
  templateUrl: "./client-histo-dialog.component.html",
  styleUrls: ["./client-histo-dialog.component.scss"],
})
export class ClientHistoDialogComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public histoClient: HistoClient[] = [];
  public client: Client;
  public totalElement: number = 0;
  public loader: boolean = true;
  public form: UntypedFormGroup;

  #infiniteScrollActive: boolean = false;
  #totalPage: number = 0;
  #destroy$: Subject<any> = new Subject();

  @ViewChild("clientHistoList", { static: true }) clientHistoList: ElementRef;

  constructor(
    private searchHistoClient: searchHistoClient,
    private formSearchHistoClient: FormSearchHistoClient,
    public dialogRef: MatDialogRef<ClientHistoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { client: Client }
  ) {}

  ngOnInit(): void {
    this.form = this.formSearchHistoClient.execute();

    this.client = this.data.client;
    this.#initForm().subscribe();
    this.form.updateValueAndValidity();
  }

  ngAfterViewInit() {
    fromEvent(this.clientHistoList.nativeElement, "scroll")
      .pipe(map(this.#infiniteScroll), takeUntil(this.#destroy$))
      .subscribe();
  }

  ngOnDestroy() {
    this.#destroy$.next(0);
    this.#destroy$.complete();
  }

  #initForm = () => {
    return this.form.valueChanges.pipe(
      tap(() => (this.loader = true)),
      map(this.#rafraichissementHisto),
      switchMap(this.#searchHistoClient),
      tap(() => (this.loader = false)),
      tap(() => (this.#infiniteScrollActive = false))
    );
  };

  #rafraichissementHisto = () => {
    if (!this.#infiniteScrollActive) {
      this.histoClient = [];
      this.form.controls.page.setValue(0, { emitEvent: false });
    }
  };

  #searchHistoClient = (): Observable<HistoClient[]> => {
    return this.searchHistoClient
      .execute(this.client.uuid, this.form.value)
      .pipe(
        debounceTime(500),
        map(this.#getHisto),
        map((histo: HistoClient[]) => (this.histoClient = histo)),
        catchError((err) => {
          this.loader = false;
          return EMPTY;
        }),
        takeUntil(this.#destroy$)
      );
  };

  #infiniteScroll = (event: any) => {
    // visible height + pixel scrolled >= total height
    if (
      event.target.scrollTop > 0 &&
      event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
    ) {
      const currentPage = this.form.controls.page.value;
      if (currentPage + 1 >= this.#totalPage) {
        return;
      }
      this.#infiniteScrollActive = true;
      const nextPage = currentPage + 1;
      this.form.controls.page.setValue(nextPage);
    }
  };

  #formatHisto = (histoClient: HistoClient[]) => {
    return histoClient.map((histo: HistoClient) => {
      const valeurInit = JSON.parse(histo.valeurInit);
      const valeurNew = JSON.parse(histo.valeurNew);

      const newHisto = {
        ...histo,
        valeurInit,
        valeurNew,
      };

      return newHisto;
    });
  };

  #getHisto = (res: HAL<HistoClient[]>) => {
    // Recupère les clients
    this.#totalPage = res.page.totalPages;
    this.totalElement = res.page.totalElements;
    const histo = res._embedded?.histoClientDTOList || [];
    const histoFormat = this.#formatHisto(histo);
    const newHisto = [...this.histoClient, ...histoFormat];

    this.histoClient = newHisto;

    return newHisto;
  };
}
