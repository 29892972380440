import {
  Component,
  EventEmitter,
  Injectable,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { StorageMap } from "@ngx-pwa/local-storage";
import { Client } from "src/app/shared/model/client";
import { ClientService } from "src/app/shared/service/client/client.service";
import { ClientsCreationDialogComponent } from "../clients-creation-dialog/clients-creation-dialog.component";
declare const wlExecute: any;
@Component({
  selector: "app-clients-creation-bouton",
  templateUrl: "./clients-creation-bouton.component.html",
  styleUrls: ["./clients-creation-bouton.component.scss"],
})
@Injectable()
export class ClientsCreationBoutonComponent implements OnInit {
  @Output() reloadClient: EventEmitter<any> = new EventEmitter();
  numeroLicence: string;
  client: Client;
  versionWD: string;
  constructor(
    public dialog: MatDialog,
    private clientService: ClientService,
    private localStorage: StorageMap
  ) {}

  async ngOnInit() {
    this.localStorage.get("numeroLicence").subscribe((data) => {
      const numLicence = data;
      if (!numLicence) {
        return;
      }
      if (typeof numLicence === "string") {
        this.numeroLicence = numLicence;
      }
    });
    this.localStorage
      .get("versionWD")
      .subscribe((versionWD) => (this.versionWD = versionWD as string));
  }
  getClientDialog = () => {
    const dialogRef = this.dialog.open(ClientsCreationDialogComponent, {
      maxWidth: "80vw",
      data: {
        numeroLicence: this.numeroLicence,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.clientService.modalOpen = false;
      if (!result || !result.client) {
        return;
      }
      const client = result.client;
      this.client = client;
      this.clientService.formSearchClient.patchValue({
        nom: this.client.nom,
        prenom: this.client.prenom,
        adresse: "",
        ville: "",
        codePostal: "",
        telephone: "",
        email: "",
      });
      this.clientService.clientQuery$.next(this.client.nom);
      const searchString = `nom=${this.client.nom}&prenom=${this.client.prenom}&codePostal`;
      wlExecute(client, this.clientService.modalOpen, this.versionWD);
      this.reloadClient.emit({ custom: true, searchString });
    });
  };
}
