<p-accordion>
  <p-accordionTab
    *ngFor="let histo of histoClient"
    header="{{ histo.dateModification | date: 'dd/MM/yyyy HH:mm' }}"
  >
    <div class="d-flex">
      <div class="flex-grow-1">
        <app-client-histo-card
          [title]="'Avant'"
          [histo]="histo.valeurInit"
        ></app-client-histo-card>
      </div>

      <div class="flex-grow-1">
        <app-client-histo-card
          [title]="'Après'"
          [histo]="histo.valeurNew"
          [histoInit]="histo.valeurInit"
        ></app-client-histo-card>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
