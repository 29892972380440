import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-clients-offres-dialog-erreur",
  templateUrl: "./clients-offres-dialog-erreur.component.html",
  styleUrls: ["./clients-offres-dialog-erreur.component.scss"],
})
export class ClientsOffresDialogErreurComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ClientsOffresDialogErreurComponent>
  ) {}

  ngOnInit(): void {}
  submit() {
    this.dialogRef.close(true);
  }
}
