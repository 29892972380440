import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class EntiteService {
  constructor(private http: HttpClient) {}

  getEntite(numeroLicence: string) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get<any>(`${environment.apiUrl}/api/entite?recherche=${numeroLicence}`)
        .subscribe(
          (response: any) => {
            resolve(response);
          },
          (error: any) => {
            reject(error);
          }
        );
    });
  }
}
