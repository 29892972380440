import { Component, Inject, Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { UUID } from "angular2-uuid";
import { MessageService } from "primeng/api";
import { FormAjouterClient } from "src/app/clients/services/form-ajouter-client.service";
import { Client } from "src/app/shared/model/client";
import { Contact } from "src/app/shared/model/contact";
import { ClientService } from "src/app/shared/service/client/client.service";

@Component({
  selector: "app-clients-creation-dialog",
  templateUrl: "./clients-creation-dialog.component.html",
  styleUrls: ["./clients-creation-dialog.component.scss"],
})
@Injectable()
export class ClientsCreationDialogComponent {
  loader = false;
  client: Client;
  formAjoutClient: UntypedFormGroup;

  constructor(
    private messageService: MessageService,
    private clientService: ClientService,
    private translate: TranslateService,
    private formAjouterClient: FormAjouterClient,
    public dialogRef: MatDialogRef<ClientsCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { numeroLicence: string; client: Client }
  ) {
    this.client = this.data.client || new Client();
    this.clientService.modalOpen = true;
  }

  ngOnInit() {
    this.#initForm();
  }

  #initForm = () => {
    const carteFidelite = this.client.uuid
      ? {
          value: this.client.carteFidelite,
          disabled: this.client.carteFidelite ? true : false,
        }
      : this.clientService.formSearchClient.controls.carteFidelite.value;
    this.formAjoutClient = this.formAjouterClient.execute(this.client.uuid);

    const formValues = {
      ...this.formAjoutClient.value,
      smsRgpd: this.client
        ? this.clientService.getRgpdSms(this.client.typeEnvoiSms)
        : false,
      emailRgpd: this.client
        ? this.clientService.getRgpdEmail(this.client.typeEnvoiEmail)
        : false,
      courrierRgpd: this.client
        ? this.clientService.getRgpdCourrier(this.client.typeEnvoiCourrier)
        : false,
      carteFidelite,
    };
    this.formAjoutClient.patchValue(formValues);

    return formValues;
  };
  /**
   * closeDialog
   */
  closeDialog(client: Client) {
    this.dialogRef.close({ client });
  }
  /**
   * openToaster
   *
   */
  openToaster(severity: string, summary: string) {
    return this.messageService.add({ severity, summary });
  }
  private padTo2Digits(num: number): string {
    return num.toString().padStart(2, "0");
  }
  public toShortFormat(date: Date): string | null {
    if (!date) {
      return null;
    }

    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join("-");
  }

  /**
   * formatClient
   *
   */
  formatClient(data: any) {
    const genereCarteFidelite = data.carteFidelite
      ? true
      : data.genereCarteFidelite;
    const dateAnniversaire = data.dateAnniversaire
      ? this.toShortFormat(data.dateAnniversaire)
      : null;
    const carteFidelite = data.carteFidelite ? data.carteFidelite : "";
    const email = data.email || "";
    const typeEnvoiCourrier = data.courrierRgpd;
    const typeEnvoiEmail = data.emailRgpd;
    const typeEnvoiSms = data.smsRgpd;
    const dateCreation = this.client.dateCreation || new Date().toISOString();
    const dateModification = new Date().toISOString();

    const newClient = {
      contacts: this.client.contacts || [],
      adresses: this.client.adresses || [],
      ...data,
      uuid: this.client.uuid || "",
      origine: "WEB",
      entiteNumeroLicence: this.data.numeroLicence,
      typeClient: "PROSPECT",
      dateAnniversaire,
      genreClient: "PARTICULIER",
      carteFidelite,
      dateCreation,
      dateModification,
      typeEnvoiCourrier,
      typeEnvoiEmail,
      typeEnvoiSms,
    };
    const adresse = {
      ...data,
    };
    this.client = newClient;
    return {
      client: newClient,
      adresse,
      genereCarteFidelite,
      email,
    };
  }
  /**
   * addContactClient
   *
   */
  async addContactClient(clientData: any) {
    const client = clientData.client;
    const uuid = clientData.client.uuid;
    // ajout ou modifie contact téléphone domicile
    client.telephoneDomicile &&
      (await this.addContactTelephone(
        uuid,
        client.telephoneDomicile,
        client.indicatifTelephoneDomicile,
        "DOMICILE"
      ));

    // ajout ou modifie contact téléphone Mobile
    client.telephoneMobile &&
      (await this.addContactTelephone(
        uuid,
        client.telephoneMobile,
        client.indicatifTelephoneMobile,
        "MOBILE"
      ));

    // ajout ou modifie adresse client
    clientData.email && (await this.addContactEmail(uuid, clientData.email));
    await this.addAdresseClient(uuid, clientData.adresse);

    this.clientService.clientQuery$.next(clientData.client.nom);
  }
  hasChanged() {
    const controls = this.formAjoutClient.controls;

    const controlChange = Object.keys(controls).find(
      (key) => !controls[key].pristine
    );

    return !!controlChange;
  }

  submitForm = (data: { closeDialog: boolean }) => {
    const hasChanged = this.hasChanged();
    if (!hasChanged && data.closeDialog) {
      return this.dialogRef.close();
    }

    // focus le bouton du formulaire afin de déclencher le blur sur l'input selectionné
    const buttonForm: HTMLElement | null = document.querySelector("#sendForm");
    if (!buttonForm) {
      return;
    }
    buttonForm.focus();
    const formValid = this.formValid();

    if (!formValid) {
      return;
    }

    const formAjoutClientValues = this.formAjoutClient.getRawValue();
    const sendValue = {
      ...formAjoutClientValues,
      closeDialog: data.closeDialog,
    };
    if (this.client.uuid) {
      return this.setClient(sendValue);
    }
    this.ajoutClient(sendValue);
  };

  formValid() {
    if (this.formAjoutClient.invalid) {
      this.formAjoutClient.markAllAsTouched();
      return false;
    }
    return true;
  }
  removeContact = async (clientData: any) => {
    if (!this.client.contacts) {
      return;
    }
    clientData.email?.length === 0 &&
      (await this.removeContactType("PERSONNEL", "EMAIL"));

    clientData.client.telephoneDomicile?.length === 0 &&
      (await this.removeContactTypeDomicile());

    clientData.client.telephoneMobile?.length === 0 &&
      (await this.removeContactType("MOBILE", "TELEPHONE"));
  };
  removeContactTypeDomicile = async () => {
    const contact = this.clientService.getTelephoneDomicile(
      this.client.contacts
    );

    if (!contact || !contact.id) {
      return;
    }
    await this.clientService.removeContact(+contact.id);
    this.client.contacts = this.client.contacts.filter(
      (item) => item.id !== contact.id
    );
  };
  removeContactType = async (type: string, modeContact: string) => {
    const contact = this.clientService.getContactByTypeNomAndModeContact(
      this.client.contacts,
      type,
      modeContact
    );

    if (!contact || !contact.id) {
      return;
    }
    await this.clientService.removeContact(+contact.id);
    this.client.contacts = this.client.contacts.filter(
      (item) => item.id !== contact.id
    );
  };

  /**
   * ajoutClient
   *
   */
  async ajoutClient(data: any) {
    this.loader = true;
    const clientData = this.formatClient(data);
    await this.addContactClient(clientData);
    // ajout carte fidelite
    const clientAvecCarteFidelite =
      clientData.genereCarteFidelite &&
      this.clientService.postCarteFideliteKtb();
    const newClient = {
      ...this.client,
      uuid: UUID.UUID(),
      carteFidelite: clientAvecCarteFidelite || "",
    };

    return await this.postClient(newClient);
  }

  async postClient(newClient: Client) {
    return await this.clientService
      .postClient(JSON.stringify(newClient))
      .then(async (res: Client) => {
        this.loader = false;
        const toasterLabel = this.translate.instant(
          "clientFicheContact.clientBienAjouter"
        );
        this.openToaster("success", toasterLabel);
        this.closeDialog(res);
      })
      .catch((e) => {
        console.error("message erreur");
      });
  }
  /**
   * SetClient
   *
   */
  setClient = async (data: any) => {
    this.loader = true;
    const closeDialog = data.closeDialog;
    const emptyClient = {} as Client;
    this.clientService.client$.next(emptyClient);
    const clientData = this.formatClient(data);
    await this.removeContact(clientData);
    await this.addContactClient(clientData);
    // ajout carte fidelite
    if (clientData.genereCarteFidelite && !clientData.client.carteFidelite) {
      const clientAvecCarteFidelite =
        await this.clientService.postCarteFidelite(clientData.client.uuid);
      this.client.carteFidelite = clientAvecCarteFidelite.carteFidelite;
    }

    if (!closeDialog) {
      this.clientService.setClient(this.client);
      this.loader = false;
      return this.clientService.client$.next(data.client);
    }

    return await this.putClient(this.client);
  };

  async putClient(newClient: Client) {
    return await this.clientService
      .putClient(newClient)
      .then(async (res: Client) => {
        this.clientService.setClient(res);
        this.loader = false;
        const toasterLabel = this.translate.instant(
          "clientFicheContact.clientModifier"
        );
        this.openToaster("success", toasterLabel);
        this.closeDialog(res);
      })
      .catch(() => {
        const toasterLabel = this.translate.instant(
          "errorMessage.modificationImpossible"
        );
        this.openToaster("error", toasterLabel);
        this.loader = false;
        return console.error("message erreur");
      });
  }
  /**
   * addAdresseClient
   *
   */
  async addAdresseClient(uuid: string, newAdresse: any) {
    const adresse = {
      paysIso2: newAdresse.pays,
      libelle1: newAdresse.libelle1 || " ",
      libelle2: newAdresse.libelle2,
      libelle3: newAdresse.libelle3,
      libelle4: newAdresse.libelle4,
      ville: newAdresse.ville,
      codePostal: newAdresse.codePostal,
      clientUuid: uuid,
      typeAdresse: "PERSONNELLE",
      npai: false,
    };
    // Ajout adresse
    if (!this.client.adresses.length) {
      return this.client.adresses.push(adresse);
    }
    // Modification adresse
    const adresseClient = {
      ...this.client.adresses[0],
      ...adresse,
      id: this.client.adresses[0].id,
      typeAdresse: "PERSONNELLE",
      npai: false,
    };
    this.client.adresses = this.replaceContact(
      adresseClient.id,
      this.client.adresses,
      adresseClient
    );

    return adresseClient;
  }

  /**
   * setContactTelephone
   *
   */
  async setContactTelephone(telephone: any, contactType: string) {
    const telephoneClient =
      this.clientService.getContactByTypeNomAndModeContact(
        this.client.contacts,
        contactType,
        "TELEPHONE"
      );
    // Ajout du téléphone
    if (!telephoneClient) {
      return this.client.contacts.push(telephone);
    }

    // Modification du téléphone
    const newTelephone = {
      ...telephoneClient,
      ...telephone,
      statut: true,
    };

    this.client.contacts = this.replaceContact(
      newTelephone.id,
      this.client.contacts,
      newTelephone
    );
    return newTelephone;
  }

  /**
   * addContactTelephone
   *
   */
  async addContactTelephone(
    uuid: string,
    valeur: string,
    indicatif: number,
    contactType: string
  ) {
    if (!valeur) {
      return;
    }
    const tel: Contact = {
      clientUuid: uuid,
      indicatif,
      contactType,
      prioritaire: true,
      modeContact: "TELEPHONE",
      valeur,
      nom: contactType === "DOMICILE" ? "DOM1" : "PORT1",
      statut: true,
    };
    if (!this.client.uuid) {
      return this.client.contacts.push(tel);
    }
    // Modification du téléphone
    return await this.setContactTelephone(tel, contactType);
  }

  /**
   * setContactEmail
   *
   */
  async setContactEmail(email: any) {
    const emailClient = this.clientService.getContactByTypeNomAndModeContact(
      this.client.contacts,
      "PERSONNEL",
      "EMAIL"
    );

    // Ajout email
    if (!emailClient) {
      return this.client.contacts.push(email);
    }

    if (
      emailClient.valeur === email.valeur &&
      emailClient.statut === email.statut
    ) {
      return;
    }
    // Modification email
    const newEmail = {
      ...emailClient,
      ...email,
      statut: true,
    };
    this.client.contacts = this.replaceContact(
      emailClient.id,
      this.client.contacts,
      newEmail
    );
    return newEmail;
  }
  replaceContact(id: any, array: any, item: any) {
    const indexArray = array.findIndex((item: any) => item.id === id);
    if (indexArray === -1) {
      return;
    }
    return this.clientService.replaceArray(indexArray, array, item);
  }
  /**
   * addContactEmail
   *
   */
  async addContactEmail(uuid: string, valeur: string) {
    const email = {
      clientUuid: uuid,
      prioritaire: true,
      modeContact: "EMAIL",
      contactType: "PERSONNEL",
      valeur,
      statut: true,
    };
    if (!this.client.uuid) {
      return this.client.contacts.push(email);
    }
    await this.setContactEmail(email);
  }
}
