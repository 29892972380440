import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PrimeNGConfig } from "primeng/api";
import { Observable } from "rxjs";
import { AuthService } from "./shared/auth/auth.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  language: string;
  loader$: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private config: PrimeNGConfig
  ) {
    this.authService.setLoader(true);
    this.language = "fr";
    this.translate.setDefaultLang(this.language);
  }
  eventFunc(e: any) {
    e.preventDefault();
  }
  ngOnInit() {
    this.translate
      .get("primeng")
      .subscribe((res: any) => this.config.setTranslation(res));
    this.loader$ = this.authService.loader$;
    var element = document.getElementById("loader-app");

    element?.classList.add("d-none");
    document.addEventListener("contextmenu", this.eventFunc, false);
    setTimeout(() => {
      document.removeEventListener("contextmenu", this.eventFunc, false);
    }, 4000);
  }
}
