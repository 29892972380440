import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-remove-dialog",
  templateUrl: "./remove-dialog.component.html",
  styleUrls: ["./remove-dialog.component.scss"],
})
export class RemoveDialogComponent implements OnInit {
  label: string;
  constructor(
    public dialogRef: MatDialogRef<RemoveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { label: string }
  ) {}

  ngOnInit() {
    this.label = this.data.label;
  }
  closeDialogWithFalse() {
    this.dialogRef.close(false);
  }
  closeDialog() {
    this.dialogRef.close(true);
  }
}
