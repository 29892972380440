<div class="h-100 px-3 py-4 px-xl-5 py-xl-4 clientEdition" *ngIf="!loader">
  <div>
    <h1>Client - {{ client.nom }} {{ client.prenom }}</h1>
  </div>
  <app-clients-form
    (update)="setClient($event)"
    (send)="ajoutClient($event)"
    [client]="client"
    [loader]="loader"
    [formAjoutClient]="formAjoutClient"
  ></app-clients-form>
</div>
