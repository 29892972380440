export class YDate {
  constructor(public readonly date: Date = new Date()) {}

  // format Date vers "yyyy-mm-dd"
  public toShortFormat(): string | null {
    if (!this.date) {
      return null;
    }

    return [
      this.date.getFullYear(),
      this.padTo2Digits(this.date.getMonth() + 1),
      this.padTo2Digits(this.date.getDate()),
    ].join("-");
  }

  public isBeforeToday(): boolean | null {
    if (!this.date) {
      return null;
    }

    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return this.date < today;
  }

  // format Date vers Date a 23h59 pour gérer l'anti datage
  public toAntiDateFormat = (): Date | null => {
    if (!this.date) {
      return null;
    }

    this.date.setHours(23, 59, 0);
    return this.date;
  };

  // convertie "5" en "05"
  private padTo2Digits(num: number): string {
    return num.toString().padStart(2, "0");
  }
}
