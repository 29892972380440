<div class="h-100 d-flex flex-column">
  <div class="mat-dialog-header position-relative">
    <div *ngIf="client.uuid">
      <h3>
        {{ "clientFiche.modifierClient" | translate }}
        <small>( Identifiant client : {{ client.uuid }} )</small>
      </h3>
    </div>
    <h3 *ngIf="!client.uuid">{{ "ajoutClient.ajouterClient" | translate }}</h3>
    <div class="close-dialog">
      <i class="pi pi-times" (click)="dialogRef.close()"></i>
    </div>
  </div>

  <app-clients-form
    (update)="setClient($event)"
    (send)="ajoutClient($event)"
    (submitForm)="submitForm($event)"
    [formAjoutClient]="formAjoutClient"
    [client]="client"
    [loader]="loader"
  ></app-clients-form>
</div>
