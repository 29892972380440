<div
  *ngIf="loader"
  class="d-flex flex-grow-1 justify-content-center align-items-center loader-dialog"
>
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<form
  *ngIf="!loader"
  class="flex-grow-1 d-flex flex-column"
  [formGroup]="formAjoutClient"
  (ngSubmit)="submit()"
>
  <div class="mat-dialog-body flex-grow-1">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <app-client-histo-bouton *ngIf="client" [client]="client">
            </app-client-histo-bouton>
            <button
              type="button"
              *ngIf="client.uuid"
              class="btn btn-transparent"
              (click)="getClientKtb()"
            >
              Interroger KTB
            </button>
          </div>
        </div>
        <div class="col-12 mb-2">
          <mat-radio-group
            formControlName="civiliteNom"
            aria-label="Select an option"
          >
            <mat-radio-button value="MADAME" class="me-2">
              {{ "formColumn.madame" | translate }}
            </mat-radio-button>
            <mat-radio-button value="MONSIEUR">
              {{ "formColumn.monsieur" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field>
            <mat-label>
              {{ "formColumn.nom" | translate }}
              <span *ngIf="formAjoutClient.get('nom') | controlRequired">
                *
                <sup>(1)</sup>
              </span>
            </mat-label>
            <input
              type="text"
              (blur)="formatString($event, 'nom')"
              matInput
              formControlName="nom"
              placeholder="{{ 'formColumn.nom' | translate }}"
            />
            <mat-error>{{ "errorMessage.nom" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field>
            <mat-label>
              {{ "formColumn.prenom" | translate }}
              <span *ngIf="formAjoutClient.get('prenom') | controlRequired">
                *
                <sup>(1)</sup>
              </span>
            </mat-label>
            <input
              type="text "
              (blur)="formatString($event, 'prenom')"
              matInput
              formControlName="prenom"
              placeholder="{{ 'formColumn.prenom' | translate }}"
            />
            <mat-error>{{ "errorMessage.prenom" | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-lg-6">
          <mat-form-field>
            <i
              *ngIf="client.adresses | ContactAdresseNpai"
              matTooltip="{{ 'clientFicheContact.npai' | translate }}"
              class="pi pi-exclamation-triangle pi-exclamation-triangle-form me-2"
            ></i>
            <mat-label>
              {{ "formColumn.libelle1" | translate }}
              <span *ngIf="formAjoutClient.get('libelle1') | controlRequired">
                *
                <sup>(1)</sup>
              </span>
            </mat-label>
            <input
              type="text "
              maxlength="35"
              (blur)="formatString($event, 'libelle1')"
              matInput
              formControlName="libelle1"
              placeholder="{{ 'formColumn.libelle1' | translate }}"
            />
            <mat-error>{{ "errorMessage.libelle1" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-2">
          <mat-form-field>
            <mat-label>
              {{ "formColumn.codePostal" | translate }}
              <span *ngIf="formAjoutClient.get('codePostal') | controlRequired">
                *
                <sup>(1)</sup>
              </span>
            </mat-label>
            <input
              type="text"
              matInput
              (keypress)="codePostalMaxSize($event)"
              formControlName="codePostal"
              (blur)="formatString($event, 'codePostal')"
              placeholder="{{ 'formColumn.codePostal' | translate }}"
              autocomplete="new-password"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              [classList]="'auto-complete'"
              [panelWidth]="250"
              (optionSelected)="setVilleWithOptionSelected($event)"
              autoActiveFirstOption
              #auto="matAutocomplete"
            >
              <mat-option *ngFor="let ville of villes | async" [value]="ville">
                <span class="text-uppercase">{{ ville.nom }}</span>
              </mat-option>
            </mat-autocomplete>
            <mat-error>{{ "errorMessage.codePostal" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-4">
          <mat-form-field>
            <mat-label>
              {{ "formColumn.ville" | translate }}
              <span *ngIf="formAjoutClient.get('ville') | controlRequired">
                *
              </span>
            </mat-label>
            <input
              type="text "
              matInput
              formControlName="ville"
              (blur)="formatString($event, 'ville')"
              placeholder="{{ 'formColumn.ville' | translate }}"
            />
            <mat-error>{{ "errorMessage.ville" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field>
            <mat-label>
              {{ "formColumn.libelle3" | translate }}
              <span *ngIf="formAjoutClient.get('libelle3') | controlRequired">
                *
              </span>
            </mat-label>
            <input
              type="text "
              maxlength="35"
              (blur)="formatString($event, 'libelle3')"
              matInput
              formControlName="libelle3"
              placeholder="{{ 'formColumn.libelle3' | translate }}"
            />
            <mat-error>{{ "errorMessage.libelle3" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <mat-form-field>
            <mat-label>
              {{ "formColumn.libelle2" | translate }}
              <span *ngIf="formAjoutClient.get('libelle2') | controlRequired">
                *
              </span>
            </mat-label>
            <input
              type="text "
              (blur)="formatString($event, 'libelle2')"
              matInput
              maxlength="35"
              formControlName="libelle2"
              placeholder="{{ 'formColumn.libelle2' | translate }}"
            />
            <mat-error>{{ "errorMessage.libelle2" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field>
            <mat-label>
              {{ "formColumn.libelle4" | translate }}
              <span *ngIf="formAjoutClient.get('libelle4') | controlRequired">
                *
              </span>
            </mat-label>
            <input
              type="text "
              (blur)="formatString($event, 'libelle4')"
              matInput
              maxlength="35"
              formControlName="libelle4"
              placeholder="{{ 'formColumn.libelle4' | translate }}"
            />
            <mat-error>{{ "errorMessage.libelle4" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-6">
          <ng-select
            *ngIf="paysIso2"
            appendTo="body"
            formControlName="pays"
            [items]="paysIso2"
            [multiple]="false"
            [clearSearchOnAdd]="true"
            [closeOnSelect]="true"
            [hideSelected]="true"
            bindLabel="nom"
            bindValue="iso2"
            placeholder="Pays"
          ></ng-select>
        </div>
        <div class="col-12 col-lg-6">
          <div
            class="calendar-form-control"
            [ngClass]="{ 'calendar-form-control--error': errorDate }"
          >
            <label [for]="'calendar-1'">
              {{ "formColumn.dateAnniversaire" | translate }}
              <span><sup>(2)</sup></span>
            </label>
            <p-calendar
              [inputId]="'calendar-1'"
              formControlName="dateAnniversaire"
              [maxDate]="maxDate"
              [selectionMode]="'single'"
              [dateFormat]="'dd/mm/yy'"
              appendTo="body"
              firstDayOfWeek="1"
              [showOnFocus]="false"
              (onBlur)="setDate($event)"
            ></p-calendar>
            <mat-error>
              {{ "errorMessage.dateAnniversaire" | translate }}
            </mat-error>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <mat-form-field>
            <i
              *ngIf="
                client.contacts
                  | contactNpai
                    : { modeContact: 'EMAIL', contactTypeNom: 'Personnel' }
              "
              matTooltip="{{ 'clientFicheContact.npai' | translate }}"
              class="pi pi-exclamation-triangle pi-exclamation-triangle-form me-2"
            ></i>
            <mat-label>
              {{ "formColumn.email" | translate }}
              <span *ngIf="formAjoutClient.get('email') | controlRequired">
                *
              </span>
            </mat-label>
            <input
              type="email "
              matInput
              formControlName="email"
              placeholder="{{ 'formColumn.email' | translate }}"
            />
            <mat-error>{{ "errorMessage.email" | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-lg-3">
          <i
            *ngIf="
              client.contacts
                | contactNpai
                  : { modeContact: 'TELEPHONE', contactTypeNom: 'Domicile' }
            "
            matTooltip="{{ 'clientFicheContact.npai' | translate }}"
            class="pi pi-exclamation-triangle pi-exclamation-triangle-form me-2"
          ></i>

          <app-telephone
            [form]="formAjoutClient"
            label="Téléphone domicile"
            paysISO2FieldForm="telephoneDomicileISO2"
            numeroTelFieldForm="telephoneDomicile"
            indicatifPaysFieldForm="indicatifTelephoneDomicile"
          >
          </app-telephone>
        </div>
        <div class="col-12 col-lg-3">
          <i
            *ngIf="
              client.contacts
                | contactNpai
                  : { modeContact: 'TELEPHONE', contactTypeNom: 'Mobile' }
            "
            matTooltip="{{ 'clientFicheContact.npai' | translate }}"
            class="pi pi-exclamation-triangle pi-exclamation-triangle-form me-2"
          ></i>
          <app-telephone
            [form]="formAjoutClient"
            label="Téléphone mobile"
            numeroTelFieldForm="telephoneMobile"
            paysISO2FieldForm="telephoneMobileISO2"
            indicatifPaysFieldForm="indicatifTelephoneMobile"
          >
          </app-telephone>
        </div>

        <div
          class="col-6"
          [ngClass]="
            formAjoutClient.controls.carteFidelite.value &&
            this.client.carteFidelite
              ? 'col-lg-3'
              : 'col-lg-6'
          "
        >
          <p>Fidélité :</p>
          <app-clients-fidelite-bouton
            *ngIf="!this.client || !this.client.carteFidelite"
            (setFidelite)="handleChangeGenereCarteFidelite($event)"
          ></app-clients-fidelite-bouton>
          <mat-form-field
            *ngIf="!formAjoutClient.controls.genereCarteFidelite.value"
            class="mt-1"
          >
            <mat-label>
              {{ "formColumn.carteFidelite" | translate }}
              <span
                *ngIf="formAjoutClient.get('carteFidelite') | controlRequired"
              >
                *
              </span>
            </mat-label>
            <input
              matInput
              formControlName="carteFidelite"
              placeholder="{{ 'formColumn.carteFidelite' | translate }}"
            />
            <mat-error
              *ngIf="
                formAjoutClient.get('carteFidelite')?.hasError('isInvalidEan13')
              "
            >
              {{ "errorMessage.carteFidelite" | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                formAjoutClient
                  .get('carteFidelite')
                  ?.hasError('isInvalidFidelite')
              "
            >
              {{ "errorMessage.carteFidelieteDejaAssocie" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div
          class="col-12 col-lg-3 d-flex align-items-center justify-content-end"
          *ngIf="
            formAjoutClient.controls.carteFidelite.value &&
            this.client.carteFidelite
          "
        >
          <div class="d-flex align-items-center">
            <img class="fidelite-svg" src="/assets/images/carte-fidelite.png" />
            <div #svgCreditCard></div>
            <div class="ms-3">
              <small>Solde point de Fidélité</small>
              <p
                class="font-weight-bold"
                [ngClass]="
                  soldeFidelite !== 'Temporairement indisponible'
                    ? 'color-secondary'
                    : 'text-danger'
                "
                *ngIf="soldeFidelite"
              >
                {{ soldeFidelite }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-6">
          <app-clients-rgpd
            [formAjoutClient]="formAjoutClient"
          ></app-clients-rgpd>
        </div>
        <div class="col-12 d-flex justify-content-end mt-2">
          <small>{{ "mentionObligatoire" | translate }}</small>
        </div>
        <div class="col-12 d-flex justify-content-end mt-2">
          <small>{{ "dateSpecification" | translate }}</small>
        </div>
      </div>
    </div>
  </div>
  <div class="mat-dialog-footer">
    <button type="button" mat-dialog-close class="btn btn-transparent me-3">
      {{ "bouton.annuler" | translate }}
    </button>
    <button type="submit" class="btn btn-secondary" id="sendForm">
      {{ "bouton.valider" | translate }}
    </button>
  </div>
</form>
