<div class="d-flex flex-column flex-grow-1 mat-dialog-body p-5">
  {{ label }}
</div>
<div class="mat-dialog-footer p-2">
  <button class="btn btn-transparent me-3" (click)="closeDialogWithFalse()">
    Annuler
  </button>
  <button type="button " class="btn btn-secondary" (click)="closeDialog()">
    Valider
  </button>
</div>
