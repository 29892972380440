<div class="checkboxFormControl">
  <div class="field-checkbox">
    <p-checkbox
      [(ngModel)]="value"
      (ngModelChange)="changed($event)"
      [binary]="true"
      [inputId]="fieldName"
      [disabled]="isDisabled"
    ></p-checkbox>
    <label [ngClass]="{ disabled: isDisabled }" [for]="fieldName">{{
      label
    }}</label>
  </div>
</div>
