import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { CalendarModule } from "primeng/calendar";
import { CheckboxModule } from "primeng/checkbox";
import { CalendarFormControlComponent } from "./calendar-form-control/calendar-form-control.component";
import { CheckboxFormControlComponent } from "./checkbox-form-control/checkbox-form-control.component";

@NgModule({
  declarations: [CalendarFormControlComponent, CheckboxFormControlComponent],
  imports: [
    CommonModule,
    CalendarModule,
    ReactiveFormsModule,
    FormsModule,
    CheckboxModule,
    TranslateModule,
  ],
  exports: [CalendarFormControlComponent, CheckboxFormControlComponent],
})
export class CustomFormControlsModule {}
