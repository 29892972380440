import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-client-histo-filtres",
  templateUrl: "./client-histo-filtres.component.html",
  styleUrls: ["./client-histo-filtres.component.scss"],
})
export class ClientHistoFiltresComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  constructor() {}

  ngOnInit(): void {}

  formReset = () => {
    this.form.reset({
      sort: this.form.controls.sort.value,
    });
  };
}
