import { Component, forwardRef, Input } from "@angular/core";
import {
  ControlValueAccessor,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";

@Component({
  selector: "app-checkbox-form-control",
  templateUrl: "./checkbox-form-control.component.html",
  styleUrls: ["./checkbox-form-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxFormControlComponent),
      multi: true,
    },
  ],
})
export class CheckboxFormControlComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() parentForm: UntypedFormGroup;
  @Input() fieldName: string;
  @Input() isDisabled: boolean;

  public value: boolean;

  public changed: (value: boolean) => void;

  public touched: () => void;

  // Accesors
  writeValue(value: boolean = false) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.changed = fn;
  }

  registerOnTouched(fn: any) {
    this.touched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
