import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-clients-fidelite-bouton",
  templateUrl: "./clients-fidelite-bouton.component.html",
  styleUrls: ["./clients-fidelite-bouton.component.scss"],
})
export class ClientsFideliteBoutonComponent {
  @Output() setFidelite: EventEmitter<any> = new EventEmitter();
  fidelite: boolean;

  constructor() {}
  handleChange() {
    this.setFidelite.emit({ carteFidelite: this.fidelite });
  }
}
