import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Client } from "src/app/shared/model/client";
import { ClientHistoDialogComponent } from "../client-histo-dialog/client-histo-dialog.component";

@Component({
  selector: "app-client-histo-bouton",
  templateUrl: "./client-histo-bouton.component.html",
  styleUrls: ["./client-histo-bouton.component.scss"],
})
export class ClientHistoBoutonComponent implements OnInit {
  @Input() client: Client;
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  getHistoClient = () => {
    const dialogRef = this.dialog.open(ClientHistoDialogComponent, {
      width: "90vw",
      height: "90vh",
      autoFocus: false,
      data: {
        client: this.client,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  };
}
