import { Adresse } from "./adresse";
import { Contact } from "./contact";
import { Offre } from "./offre";

export class Client {
  activiteCommerceInterdite?: boolean;
  civiliteNom?: string;
  adresses: Adresse[] = [];
  carteFidelite?: string;
  codeMagasin?: string;
  contacts: Contact[] = [];
  dateAnniversaire?: string;
  dateCreation?: string;
  dateModification?: string;
  dateSuppression?: string;
  dateMariage?: string;
  entiteNumeroLicence: string;
  genreClient: string;
  societe?: string;
  nom: string;
  origine: string;
  professionnel?: {
    tarifSpecial?: string;
    tarifActivite?: string;
    operationPromo?: string;
    emailEDI?: string;
    gestionnaireClient?: string;
    codeGroupement?: string;
    numeroTVA?: string;
    modeReglement?: string;
    iban?: string;
    domiciliationBancaire?: string;
    solde?: number;
    typeActivite?: string;
  };
  offres?: Offre[];
  prenom?: string;
  typeClient: string;
  typeEnvoiCourrier?: string;
  typeEnvoiEmail?: string;
  typeEnvoiSms?: string;
  typeLibre?: string;
  uuid: string;
  tauxRemise?: number;
  isDeleted?: boolean;
}
