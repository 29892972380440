import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-client-histo-card-contacts",
  templateUrl: "./client-histo-card-contacts.component.html",
  styleUrls: ["./client-histo-card-contacts.component.scss"],
})
export class ClientHistoCardContactsComponent implements OnInit {
  @Input() title: string = "";
  @Input() subtitle: string = "";
  @Input() contacts: any[] = [];
  @Input() histoInit!: any;
  constructor() {}

  ngOnInit(): void {}
}
