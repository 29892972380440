import { Component, Input, OnInit } from "@angular/core";
import { HistoClient } from "src/app/shared/model/histoClient";
declare var diffObject: any;

@Component({
  selector: "app-client-histo-list",
  templateUrl: "./client-histo-list.component.html",
  styleUrls: ["./client-histo-list.component.scss"],
})
export class ClientHistoListComponent implements OnInit {
  @Input() histoClient: HistoClient[];
  constructor() {}
  ngOnInit(): void {}
}
