import { registerLocaleData } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { StorageModule } from "@ngx-pwa/local-storage";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { FilterService, MessageService, PrimeNGConfig } from "primeng/api";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { environment } from "src/environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { SessionExpireComponent } from "./session-expire/session-expire.component";
import { AuthInterceptor } from "./shared/auth/auth.interceptor";
import { AuthModule } from "./shared/auth/auth.module";
import { AuthService } from "./shared/auth/auth.service";
import { ContactAdresseFilterPipe } from "./shared/pipe/contact-adresse-filter/contact-adresse-filter.pipe";
import { ContactFilterPipe } from "./shared/pipe/contact-filter/contact-filter.pipe";
import { ContactNomPaysPipe } from "./shared/pipe/contact-nom-pays/contact-nom-pays.pipe";
import { ControlRequiredPipe } from "./shared/pipe/control-required/control-required.pipe";
import { ClientService } from "./shared/service/client/client.service";
import { EntiteService } from "./shared/service/entite/entite.service";
import { LoginService } from "./shared/service/login/login.service";

import { MatBadgeModule } from "@angular/material/badge";
import { MatMenuModule } from "@angular/material/menu";
import { LoaderModule } from "./shared/components/loader/loader.module";
import { ContactAdresseNpaiPipe } from "./shared/pipe/contact-adresse-npai/contact-adresse-npai.pipe";
import { ContactNpaiPipe } from "./shared/pipe/contact-npai/contact-npai.pipe";
import { LaposteService } from "./shared/service/laposte/laposte.service";

import { AccordionModule } from "primeng/accordion";
import { CalendarModule } from "primeng/calendar";
import { CardModule } from "primeng/card";
import { ClientHistoBoutonComponent } from "./clients/components/client/client-histo/client-histo-bouton/client-histo-bouton.component";
import { ClientHistoCardContactsComponent } from "./clients/components/client/client-histo/client-histo-card/client-histo-card-contacts/client-histo-card-contacts.component";
import { ClientHistoCardComponent } from "./clients/components/client/client-histo/client-histo-card/client-histo-card.component";
import { ClientHistoDialogComponent } from "./clients/components/client/client-histo/client-histo-dialog/client-histo-dialog.component";
import { ClientHistoFiltresComponent } from "./clients/components/client/client-histo/client-histo-filtres/client-histo-filtres.component";
import { ClientHistoListComponent } from "./clients/components/client/client-histo/client-histo-list/client-histo-list.component";
import { ClientsCreationBoutonComponent } from "./clients/components/clients/clients-creation-bouton/clients-creation-bouton.component";
import { ClientsCreationDialogComponent } from "./clients/components/clients/clients-creation-dialog/clients-creation-dialog.component";
import { ClientsFideliteBoutonComponent } from "./clients/components/clients/clients-fidelite-bouton/clients-fidelite-bouton.component";
import { ClientsFormComponent } from "./clients/components/clients/clients-form/clients-form.component";
import { ClientsListComponent } from "./clients/components/clients/clients-list/clients-list.component";
import { ClientsOffresDialogErreurComponent } from "./clients/components/clients/clients-offres-dialog-erreur/clients-offres-dialog-erreur.component";
import { ClientsOffresDialogComponent } from "./clients/components/clients/clients-offres-dialog/clients-offres-dialog.component";
import { ClientsRechercheComponent } from "./clients/components/clients/clients-recherche/clients-recherche.component";
import { ClientsRgpdComponent } from "./clients/components/clients/clients-rgpd/clients-rgpd.component";
import { ClientEditionComponent } from "./clients/pages/client-edition/client-edition.component";
import { ClientsComponent } from "./clients/pages/clients/clients.component";
import { FormAjouterClient } from "./clients/services/form-ajouter-client.service";
import { FormSearchHistoClient } from "./clients/services/form-search-histo-client.service";
import { CustomFormControlsModule } from "./shared/components/custom-form-control/custom-form-controls.module";
import { ClientHistoriqueFiltrePipe } from "./shared/pipe/client-historique-filtre/client-historique-filtre.pipe";
import { ClientHistoriqueModificationPipe } from "./shared/pipe/client-historique-modification/client-historique-modification.pipe";
import { NgSelectModule } from "@ng-select/ng-select";
import { TelephoneComponent } from "./shared/components/telephone/telephone.component";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";

export const CUSTOM_FORMAT = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MM YYYY",
  },
};

registerLocaleData(localeFr, "fr");

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ClientsComponent,
    NotFoundComponent,
    ClientsListComponent,
    ClientsRechercheComponent,
    ClientsCreationDialogComponent,
    ClientsCreationBoutonComponent,
    ClientsFormComponent,
    ContactFilterPipe,
    TelephoneComponent,
    ContactAdresseFilterPipe,
    ContactNomPaysPipe,
    ControlRequiredPipe,
    ClientsRgpdComponent,
    ClientsFideliteBoutonComponent,
    SessionExpireComponent,
    ClientsOffresDialogComponent,
    ContactNpaiPipe,
    ContactAdresseNpaiPipe,
    ClientsOffresDialogErreurComponent,
    ClientEditionComponent,
    ClientHistoDialogComponent,
    ClientHistoBoutonComponent,
    ClientHistoListComponent,
    ClientHistoFiltresComponent,
    ClientHistoCardComponent,
    ClientHistoCardContactsComponent,
    ClientHistoriqueModificationPipe,
    ClientHistoriqueFiltrePipe,
  ],
  imports: [
    BrowserModule,
    CustomFormControlsModule,
    BrowserAnimationsModule,
    AuthModule,
    TableModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatInputModule,
    MatDialogModule,
    MatRadioModule,
    MatBadgeModule,
    MatCheckboxModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    ToastModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    DropdownModule,
    LoaderModule,
    AccordionModule,
    CalendarModule,
    CardModule,
    InputTextModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    StorageModule.forRoot({ IDBNoWrap: true }),
  ],
  providers: [
    FilterService,
    ClientService,
    LoginService,
    LaposteService,
    EntiteService,
    PrimeNGConfig,
    MessageService,
    FormSearchHistoClient,
    FormAjouterClient,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: "fr",
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
