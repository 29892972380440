import { Component, Input } from "@angular/core";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { Client } from "src/app/shared/model/client";
import { FormAjouterClient } from "src/app/shared/model/formAjouterClient";

@Component({
  selector: "app-clients-rgpd",
  templateUrl: "./clients-rgpd.component.html",
  styleUrls: ["./clients-rgpd.component.scss"],
})
export class ClientsRgpdComponent {
  @Input() client: Client;
  @Input() formAjoutClient: UntypedFormGroup;
  form: UntypedFormGroup;
  smsDisabled: boolean = true;
  emailDisabled: boolean = true;
  #destroy$: Subject<any> = new Subject();
  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    const valueForm = this.formAjoutClient.value;

    const email = valueForm?.email;
    const telephoneMobile = valueForm?.telephoneMobile;
    const courrierRgpd =
      valueForm.courrierRgpd && valueForm.courrierRgpd !== "AUCUN"
        ? true
        : false;
    if (telephoneMobile && telephoneMobile.length) {
      this.smsDisabled = false;
    }
    if (email && email.length) {
      this.emailDisabled = false;
    }

    this.form = this.fb.group({
      smsRgpd: [
        valueForm.smsRgpd && valueForm.smsRgpd !== "AUCUN" ? true : false,
      ],
      emailRgpd: [
        valueForm.emailRgpd && valueForm.emailRgpd !== "AUCUN" ? true : false,
      ],
      courrierRgpd: [courrierRgpd],
    });
    this.#formChange().subscribe();
    this.#formParentChange().subscribe();
  }

  ngOnDestroy() {
    this.#destroy$.next(true);
    this.#destroy$.complete();
  }

  #formChange = (): Observable<any> => {
    return this.form.valueChanges.pipe(
      map(() => {
        const obj = {
          ...this.formAjoutClient.value,
          emailRgpd: this.form.controls.emailRgpd.value
            ? "TECHNIQUE_COMMERCIAL"
            : "AUCUN",
          courrierRgpd: this.form.controls.courrierRgpd.value
            ? "TECHNIQUE_COMMERCIAL"
            : "AUCUN",
          smsRgpd: this.form.controls.smsRgpd.value
            ? "TECHNIQUE_COMMERCIAL"
            : "AUCUN",
        };
        this.formAjoutClient.controls.courrierRgpd.markAsDirty();

        this.formAjoutClient.setValue(obj);
      }),
      takeUntil(this.#destroy$)
    );
  };

  #formParentChange = (): Observable<any> => {
    return this.formAjoutClient.valueChanges.pipe(
      map(this.#disabledCheckbox),
      takeUntil(this.#destroy$)
    );
  };

  #disabledCheckbox = (changes: FormAjouterClient) => {
    this.#disabledSms(changes?.telephoneMobile);
    this.#disabledEmail(changes?.email);
  };

  #disabledSms = (telephoneMobile: string) => {
    if (telephoneMobile && telephoneMobile.length) {
      return (this.smsDisabled = false);
    }

    return (this.smsDisabled = true);
  };

  #disabledEmail = (email: string) => {
    if (email && email.length) {
      return (this.emailDisabled = false);
    }
    return (this.emailDisabled = true);
  };
}
