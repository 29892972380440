import { HttpResponse } from "@angular/common/http";
import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { of } from "rxjs";
import { first, map } from "rxjs/operators";
import { Client } from "../model/client";
import { ClientService } from "../service/client/client.service";
declare var checkEan: any;

export function carteFideliteValidator(
  clientService: ClientService,
  uuidClient?: string
): AsyncValidatorFn {
  return (control: AbstractControl): any => {
    const numFidelite = control.value;
    if (!numFidelite || !numFidelite.length) {
      return of(null);
    }
    const testEan = checkEan(numFidelite);
    if (!testEan) {
      return of({ isInvalidEan13: true });
    }
    const query = `carteFidelite=${control.value}`;
    return clientService.searchClientInput(query).pipe(
      first(),
      map((res: HttpResponse<Client[]>) => {
        const totalCount = res.headers.get("X-Total-Count");

        if (totalCount && +totalCount > 0 && res.body) {
          if (res?.body[0].uuid === uuidClient) {
            return null;
          }
          return { isInvalidFidelite: true };
        }
        return null;
      })
    );
  };
}
