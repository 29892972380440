import { Component, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import { filter, finalize, map } from "rxjs/operators";
import { YDate } from "src/app/core/utils/date";
import { RemoveDialogComponent } from "src/app/remove-dialog/remove-dialog.component";
import { Client } from "src/app/shared/model/client";
import { Offre } from "src/app/shared/model/offre";
import { ClientService } from "src/app/shared/service/client/client.service";

@Component({
  selector: "app-clients-offres-dialog",
  templateUrl: "./clients-offres-dialog.component.html",
  styleUrls: ["./clients-offres-dialog.component.scss"],
})
export class ClientsOffresDialogComponent {
  offres: Offre[] = [];
  offresSelected: Offre[] = [];
  dayDate: string | null;
  client: Client;
  loader: boolean = true;

  constructor(
    public dialog: MatDialog,
    private translateService: TranslateService,
    private clientService: ClientService,
    public dialogRef: MatDialogRef<ClientsOffresDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { getOffres: any; client: Client }
  ) {
    this.dayDate = new YDate().toShortFormat();
    this.client = this.data.client;
    this.clientService.modalOpen = false;
  }

  ngOnInit() {
    this.data
      .getOffres(this.client)
      .pipe(
        filter((offre) => !!offre),
        map((offres: Offre[]) => {
          this.offres = this.formatOffres(offres);
        }),
        finalize(() => (this.loader = false))
      )
      .subscribe();
  }

  formatOffres = (offres: Offre[]) => {
    const newOffres: Offre[] = [];
    offres.reduce((_: any, curr) => {
      if (!curr) {
        return;
      }
      const dateF = new YDate(new Date(curr.dateFin));
      const newOffre = {
        ...curr,
        dateFin: dateF.toISOStringSansPrendreEnCompteLeFuseauHoraire(),
        dateDebut: new YDate(
          new Date(curr.dateDebut)
        ).toISOStringSansPrendreEnCompteLeFuseauHoraire(),
        dateEnvoi: new YDate(
          new Date(curr.dateEnvoi)
        ).toISOStringSansPrendreEnCompteLeFuseauHoraire(),
      };
      newOffres.push(newOffre);
      return curr;
    }, newOffres);

    return newOffres;
  };

  isCurrent(offre: Offre) {
    const offreCurrent = this.offresSelected.find(
      (offreSelected) => offreSelected.numero === offre.numero
    );
    if (!offreCurrent) {
      return false;
    }
    return true;
  }

  getOffreValidationDialog(offre: Offre) {
    const label = this.translateService.instant("offres.utiliseeOffre");
    const dialogRef = this.dialog.open(RemoveDialogComponent, {
      data: {
        label,
      },
    });
    dialogRef.afterClosed().subscribe(async (valide: boolean) => {
      if (!valide) {
        return false;
      }
      offre.utilisee = true;

      const removeOffre = await this.clientService.removeOffresClient(
        this.client.uuid,
        offre.numero
      );
      if (!removeOffre) {
        return false;
      }
      this.offresSelected.push(offre);
    });
  }
  submit() {
    this.dialogRef.close(this.offresSelected);
  }
}
