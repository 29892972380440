export enum Source {
  dev = "https://yvidya-dev.herokuapp.com",
  preprod = "https://api-test.yvidya.fr",
  prod = "https://api.yvidya.fr",
}
export enum LoaderSource {
  inmemory,
  here,
  yvidya,
  adresseDataGouv,
}
