import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DateFormatType } from "src/app/shared/model/dateFormatType";
import { DatesParamsNoms } from "src/app/shared/model/dateParamsNoms";

import { HAL } from "src/app/shared/model/hal";
import { HistoClient } from "src/app/shared/model/histoClient";
import { ClientService } from "src/app/shared/service/client/client.service";
import {
  cleanObject,
  toRangeDateParams,
} from "src/app/shared/variables-globale/common/common";

@Injectable({
  providedIn: "root",
})
export class searchHistoClient {
  constructor(private clientService: ClientService) {}

  execute = (uuid: string, params: any): Observable<HAL<HistoClient[]>> => {
    const paramsWithDatesFormatted = this.#formatDateParams(params);

    const paramsSearch = {
      page: params.page,
      sort: params.sort,
      ...paramsWithDatesFormatted,
    };

    return this.clientService
      .searchHistoClient(uuid, cleanObject(paramsSearch))
      .pipe();
  };

  #formatDateParams = (searchParams: any) => {
    const datesParamsDepot = new DatesParamsNoms(
      "dateRangeMinMax",
      "dateMin",
      "dateMax"
    );
    searchParams = toRangeDateParams(
      searchParams,
      datesParamsDepot,
      DateFormatType.iso
    );

    return searchParams;
  };
}
