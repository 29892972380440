import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import parsePhoneNumberFromString from "libphonenumber-js";
import { Page } from "../model/Page";

export function getNextPage(link: string) {
  if (!link) {
    return null;
  }
  const links = link.split(",");
  if (!links.length) {
    return null;
  }

  const nextLink = links.find((link) => link.includes("next"));
  if (!nextLink) {
    return null;
  }
  const linkTab = nextLink.replace("<", "").replace(">", "").split(";");
  const page = getParameterByName("page", linkTab[0]);
  return page;
}

export function blobToUrl(src: Blob): string {
  const pdf = URL.createObjectURL(src);
  const params = new URLSearchParams();
  params.set("toolbar", "1");
  const fullUrl = pdf + "#" + params.toString();
  return fullUrl;
}
function getParameterByName(name: string, url: string): number | null {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return 0;
  const pageNumber = decodeURIComponent(results[2].replace(/\+/g, " "));
  return +pageNumber;
}

export const blobToBase64 = async (
  blob: Blob
): Promise<string | ArrayBuffer> => {
  const promise64: Promise<string | ArrayBuffer> = new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
  return promise64;
};
export const openPdf = (blob: Blob): Blob => {
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl, "_blank");
  return blob;
};
export const downloadPdf = (blob: Blob, filename: string): Blob => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
  return blob;
};

export function replaceAt(index: number, items: any[], value: string) {
  const arr = [...items];
  const newArray = [...items.slice(0, index), value, ...arr.slice(index + 1)];
  return newArray;
}

export function checkEmail(term: string) {
  if (/^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@/.test(term)) {
    return true;
  }
  return false;
}

export function testChecksum(ean: any) {
  const digits = ean.slice(0, -1);
  const checkDigit = ean.slice(-1) | 0;
  let sum = 0;
  for (let i = digits.length - 1; i >= 0; i--) {
    sum += (digits.charAt(i) * (1 + 2 * (i % 2))) | 0;
  }
  sum = (10 - (sum % 10)) % 10;
  return sum === checkDigit;
}

export function formatStringWithoutDiacritics(valeur: string): string {
  const valeurSansApostrophe = valeur.replace(/'/g, " ");
  const valeurSansDiacritics = valeurSansApostrophe.replace(
    /[`~!@#$%^&*()_|+\=?;:'',.<>\{\}\[\]\\\/]/gi,
    ""
  );
  const valeurFormate = valeurSansDiacritics
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  return valeurFormate.toUpperCase();
}
function replaceLettreParCodeASCII(digit: string) {
  if (/[A-Za-z]/.test(digit)) {
    return digit.charCodeAt(0);
  }
  return +digit;
}
function calculateEAN13Checksum(productCode: string) {
  const parts = productCode.split(".");
  const digits = parts[0] + parts[1];
  let si = 0;
  let sp = 0;
  for (let i = 0; i < digits.length; i++) {
    const j = i + 1;
    const digit = replaceLettreParCodeASCII(digits[i]);
    j % 2 === 0 ? (sp += digit) : (si += digit);
  }
  const totalSum = si + 3 * sp;
  const checksum = (10 - (totalSum % 10)) % 10;
  return checksum;
}

export const verifierSiCestUnCodeArticle = (codeArticle: string) => {
  // Recherche de la première occurrence du caractère '.'
  const indexOfDot = codeArticle.indexOf(".");

  // Vérification si le point existe et s'il y a au moins 2 caractères après le point
  if (indexOfDot !== -1 && codeArticle.length - indexOfDot > 2) {
    return true;
  }

  // Si aucun point n'est trouvé ou moins de 2 caractères après le point
  return false;
};

export function generateCodeArticle(codeArticle: string) {
  const key = calculateEAN13Checksum(codeArticle);
  return `${codeArticle}${key}`;
}

function contientUniquementDesChiffres(chaine: string) {
  return /^[0-9]+$/.test(chaine);
}

export function transformCodeArticleToLogicielId(
  chaine: string,
  identifiant: string
): string {
  if (!chaine || !chaine.includes(".")) {
    return "";
  }
  const chainTab = chaine.split(".");
  const partieLogicielIdAvecCle = chainTab[1];

  const codeArticle = `${identifiant}.${partieLogicielIdAvecCle}`;

  const logicielIdTab = Array.from(chaine);
  const dernierCaract = logicielIdTab[chaine.length - 1];

  const key = calculateEAN13Checksum(codeArticle.slice(0, -1));
  if (key !== +dernierCaract) {
    return "";
  }
  // Supprimer le dernier chiffre de la partie après le point
  return partieLogicielIdAvecCle.slice(0, -1);
}

export const verifierSiCestUnCodeEAN = (eanCode: string): boolean => {
  const regex = new RegExp("^[0-9]{13}$");
  return regex.test(eanCode);
};

/*
 * convertirStringChiffresToStringDate
 * Convertie une chaine de caractere 12052020 en 12/05/2020 OU 120520 en 12/05/2020
 */
export const convertirStringChiffresToStringDate = ({
  inputDate,
  isDateAnniversaire,
}: {
  inputDate: string;
  isDateAnniversaire: boolean;
}) => {
  //Vérifie si la chaine de caractere comporte 6 chiffres
  const isValeurEgal6Chiffres = /^\d{6}$/.test(inputDate);
  //Vérifie si la chaine de caractere comporte 8 chiffres
  const isValeurEgal8Chiffres = /^\d{8}$/.test(inputDate);

  if (isValeurEgal8Chiffres) {
    const date = inputDate.substring(0, 2);
    const month = inputDate.substring(2, 4);
    const year = inputDate.substring(4, 8);
    return `${date}/${month}/${year}`;
  }

  if (isValeurEgal6Chiffres) {
    const date = inputDate.substring(0, 2);
    const month = inputDate.substring(2, 4);
    const year = isDateAnniversaire
      ? convertirAnneeAnniversaireEn2ChiffresEn4Chiffres(inputDate.slice(4))
      : convertirAnneeEn2ChiffresEn4Chiffres(inputDate.slice(4));
    return `${date}/${month}/${year}`;
  }

  return inputDate;
};

/*
 * convertirDateFRStringVersDateStandardJS
 * Convertie une date 12/12/2000 en date JS
 */
export const convertirDateFRStringVersDateStandardJS = (
  date: string
): Date | null => {
  const parts: string[] = date.split("/");
  if (!parts || parts.length !== 3) {
    return null;
  }
  const dateObject = new Date(
    parseInt(parts[2]),
    parseInt(parts[1]) - 1,
    parseInt(parts[0])
  );

  return dateObject;
};

//Convertie une annee de 2 chiffres en 4 chiffres, par exemple 23 en 2023 OU 98 en 1998
const convertirAnneeAnniversaireEn2ChiffresEn4Chiffres = (
  year: string
): string => {
  const currentYear: string = new Date().getFullYear().toString();
  const currentYearDigits: number = Number(currentYear.slice(2));

  const currentYearDigitsMinusFive: number = currentYearDigits - 5;

  if (Number(year) > currentYearDigitsMinusFive) {
    return `19${year}`;
  }

  return `20${year}`;
};

const convertirAnneeEn2ChiffresEn4Chiffres = (year: string): string => {
  const currentYear: string = new Date().getFullYear().toString();
  const currentYearDigits: number = Number(currentYear.slice(2));

  if (Number(year) > currentYearDigits) {
    return `19${year}`;
  }

  return `20${year}`;
};

/*
 * cleanObject
 * retire les propriétés null ou undefined de l'objet
 */
export const cleanObject = (obj: any) => {
  for (var propName in obj) {
    const condition =
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName].length === 0;

    if (condition) {
      delete obj[propName];
    }
  }

  return obj;
};

/*
 * getTheHightestId
 * retourne l'objet avec le champ id le plus élevé
 */
export const getTheHightestId = (array: any[]): any => {
  return array.reduce((prev, current) => {
    return prev.snapshot().id > current.snapshot().id ? prev : current;
  });
};
export const customAlphanumericSort = (a: any, b: any) => {
  // Utiliser la fonction locale "localeCompare" pour effectuer un tri alphanumérique
  return a.localeCompare(b, undefined, { numeric: true, sensitivity: "base" });
};

/*
 * toUrlWithParams
 * transforme des params de recherche sous form d'objet en query string
 */
export const toUrlWithParams = (url: string, params: any): string => {
  const fullUrl = new URL(url);

  fullUrl.search = toSearchParams(params);

  return fullUrl.toString();
};

/*
 * toSearchParams
 * transforme des params de recherche sous form d'objet en query string
 */
export const toSearchParams = (params: any): string => {
  if (!params) {
    return "";
  }

  if (!params?.sort) {
    return new URLSearchParams(params).toString();
  }

  const { sort, ...othersParams } = params;
  const searchParams = new URLSearchParams(othersParams).toString();
  const sortParam = toSortParam(sort, othersParams);
  return searchParams + sortParam;
};
/*
 * toSortParam
 * construction du query param pour trier les resultats
 * on passe par exemple : sort: 'date,desc'
 */
const toSortParam = (sort: string, othersParams: any) => {
  const esperluette = Object.keys(othersParams).length === 0 ? "" : "&";

  return sort ? `${esperluette}sort=${sort}` : "";
};

export const dateIsValid = (date: Date): boolean => {
  return date instanceof Date;
};

/*
 * getRoundedAmount
 *
 */
export function getRoundedAmount(amount: number): number {
  return Math.round((amount + Number.EPSILON) * 100) / 100;
}

/*
 * decimalCount
 *
 */
export function getDecimalCount(num: number): number {
  // Convert to String
  const numStr = String(num);
  // String Contains Decimal
  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  }
  // String Does Not Contain Decimal
  return 0;
}

/*
 * roundToTwoDecimals
 *
 */
export function getRoundedAmountToTwoDecimals(
  decimalCount: number,
  roundedAmount: number
): string {
  if (decimalCount == 2) {
    return `${roundedAmount}`;
  }

  const roundedAmountStr = String(roundedAmount);

  const preparedRoundedAmountStr =
    decimalCount === 0 ? `${roundedAmountStr}.00` : `${roundedAmountStr}0`;
  return preparedRoundedAmountStr;
}

/*
 * formatLogicielId
 *
 */
export function formatLogicielId(logicielId: string) {
  if (!logicielId || logicielId.length <= 3) {
    return logicielId;
  }

  const trimLogicielId = logicielId.trim();
  const GFSFLength = getGFSFLength(trimLogicielId);

  const GFSF = trimLogicielId.substring(0, GFSFLength).toUpperCase();
  const GFSFIncrement = trimLogicielId
    .substring(GFSFLength, trimLogicielId.length)
    .trim();

  const newGFSFIncrement =
    GFSFIncrement.length < 5 ? addLeadingZeros(GFSFIncrement) : GFSFIncrement;

  const newLogicielId =
    GFSFLength === 3
      ? `${GFSF} ${newGFSFIncrement}`
      : `${GFSF}${newGFSFIncrement}`;

  return newLogicielId;
}

/*
 * getGFSFLength
 *
 */
export function getGFSFLength(logicielId: string) {
  return logicielId[3].match(/[a-zA-Z]/) ? 4 : 3;
}

/*
 * addLeadingZeros
 * Ajoute "size" zéros au début de "sentence"
 */
export function addLeadingZeros(GFSFIncrement: string) {
  const splitedGFSFIncrement = GFSFIncrement.split("");
  const numberOfMissingZero = 5 - splitedGFSFIncrement.length;
  for (let index = 0; index < numberOfMissingZero; index++) {
    splitedGFSFIncrement.splice(0, 0, "0");
  }
  return splitedGFSFIncrement.join("");
}

/*
 * getAllPages
 * @Params :
 * - getPage(): export Function                   callback pour récupérer une page de l'api
 * - setPage(page)(page: number): export Function     callback pour mettre à jour la valeur de la prochaine page à récupérer
 */
export async function getAllPages(getPage: Function, setPage: Function) {
  let items: any = [];
  let page: number | null = 0;

  do {
    const res = await getPage();
    if (!res) {
      return null;
    }

    const nextItemsList = res.body || [];
    items = [...items, ...nextItemsList];

    const link = res.headers.get("Link");
    page = getNextPage(link);
    setPage(page);
  } while (page !== null);

  return items;
}

/*
 * getAllPagesV2 (format de réponse au suivant le modèle Page (spring boot template))
 * @Params :
 * - getPage(): export Function                   callback pour récupérer une page de l'api
 * - setPage(page)(page: number): export Function     callback pour mettre à jour la valeur de la prochaine page à récupérer
 */
export async function getAllPagesV2(
  getPage: Function,
  setPageNumber: Function
) {
  let items: any = [];
  let page = 0;
  let totalPages = 0;

  do {
    const res = (await getPage()) as Page<any[]>;

    if (!res) {
      return null;
    }

    const nextItemsList = res.content || [];
    items = [...items, ...nextItemsList];

    const nextPage = res?.pageable?.pageNumber || 0;
    page = nextPage + 1;
    totalPages = res.totalPages;
    setPageNumber(page);
  } while (page < totalPages);

  return items;
}

/**
 * deepEqual
 * Compare deux objets en itérant sur les propriétés
 * Retourne true si les deux objets sont égaux
 * @returns
 */
export function deepEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Detecte si le paramètre reçu est un objet
 * @param object
 * @returns
 */
function isObject(object: any) {
  return object != null && typeof object === "object";
}

/**
 * handleCatchError
 * @param error
 * @returns
 */
export const handleCatchError = (error: HttpErrorResponse) => {
  let errorMessage: string;
  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    console.error("An error occurred:", error.error.message);
    errorMessage = `An error occured: ${error.error.message}`;
  } else {
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong.
    console.error(
      `Backend returned code ${error.status}, ` + `body was: ${error.error}`
    );
    errorMessage =
      `Backend returned code ${error.status}, ` + `body was: ${error.error}`;
  }
  // Return an observable with a user-facing error message.
  return throwError(() => error);
};

export const sortDescending = (array: any[], key: string) => {
  return array.sort((a, b) => (a[key] > b[key] ? -1 : a[key] > b[key] ? 1 : 0));
};

/**
 * calculeTVA
 * @param ttc
 * @param ht
 * @param tp
 * @returns {number}
 */
export const calculeTVA = (ttc: number, ht: number, tp: number): number => {
  const tva = ttc - ht - tp;
  return Math.round((tva + Number.EPSILON) * 100) / 100;
};

/**
 * Get le paysISO2 d'un numéro de téléphone
 */
export const getMobilePaysISO2 = (contact: any): string => {
  if (!contact) {
    return "FR";
  }

  try {
    const indicatif: number = contact.indicatif;
    const valeur: string = contact.valeur;
    const valeurAvecIndicatif: string = getNumeroTelAvecIndicatif(
      indicatif,
      valeur
    );

    const phoneNumber = parsePhoneNumberFromString(valeurAvecIndicatif);
    return phoneNumber?.country || "FR";
  } catch (e) {
    return "FR";
  }
};

/**
 * Retourne le numero de telephone avec son indicatif
 */
const getNumeroTelAvecIndicatif = (
  indicatif: number,
  valeur: string
): string => {
  //Test si il y a plus dans la valeur pour savoir si l'indicatif est déja présent
  const regex = /^\+\d+/;

  if (regex.test(valeur)) {
    return valeur;
  }
  return `+${indicatif}${valeur}`;
};
