<p-card [header]="title">
  <div class="d-flex flex-column">
    <div
      *ngFor="let item of histo | keyvalue"
      [class.order-2]="item.key === 'adresses' || item.key === 'contacts'"
      [class.d-none]="item.key | clientHistoriqueFiltre"
    >
      <p
        *ngIf="item.key !== 'adresses' && item.key !== 'contacts'"
        [ngClass]="item | clientHistoriqueModification: histoInit"
      >
        {{ item.key }} : {{ item.value || "null" }}
      </p>
      <div *ngIf="item.key === 'adresses'" class="pt-2 pb-2">
        <app-client-histo-card-contacts
          [title]="'Adresses'"
          [subtitle]="'Adresse'"
          [contacts]="item.value"
          [histoInit]="histoInit?.adresses"
        >
        </app-client-histo-card-contacts>
      </div>
      <div *ngIf="item.key === 'contacts'" class="pt-2 pb-2">
        <app-client-histo-card-contacts
          [title]="'Contacts'"
          [subtitle]="'Contact'"
          [contacts]="item.value"
          [histoInit]="histoInit?.contacts"
        >
        </app-client-histo-card-contacts>
      </div>
    </div>
  </div>
</p-card>
