import { Pays } from "../../../domain/pays/pays";
import { YvidyaPaysDTO } from "./yvidya-pays.dto";

export class YvidyaPaysMapper {
  static mapToPays = (yvidyaPaysDTO: YvidyaPaysDTO): Pays => {
    return new Pays({
      id: yvidyaPaysDTO.id,
      nom: yvidyaPaysDTO.nom,
      iso2: yvidyaPaysDTO.iso2,
      iso3: yvidyaPaysDTO.iso3,
    });
  };

  static mapToPaysDTO = (pays: Pays): YvidyaPaysDTO => ({
    id: pays.snapshot().id || null,
    nom: pays.snapshot().nom,
    iso2: pays.snapshot().iso2,
    iso3: pays.snapshot().iso3 || null,
  });
}
