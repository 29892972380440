import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { SearchPays } from '../usecases/search-pays/search-pays'

import { ConfigDIFactory } from './configDI.factory'

export const PaysDIProvider = {
  searchPays: {
    provide: 'SearchPays',
    useFactory: (http: HttpClient) =>
      new SearchPays(ConfigDIFactory.paysLoader(http)),
    deps: [HttpClient, HTTP_INTERCEPTORS],
  },
}
