import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/core/utils/apiResponse";
import { Pays } from "../../../domain/pays/pays";

import { PaysLoader } from "../../../domain/pays/pays.loader";

export class InMemoryPaysLoader implements PaysLoader {
  pays$: BehaviorSubject<Pays[]> = new BehaviorSubject<Pays[]>([]);

  constructor(pays: Pays[]) {
    this.pays$.next(pays);
  }

  search(): Observable<ApiResponse<Pays[]>> {
    return this.pays$.pipe(
      map((pays: Pays[]) => {
        return {
          body: pays,
          page: {
            number: 1,
            size: 20,
            totalElements: 100,
            totalPages: 5,
          },
        };
      })
    );
  }
}
