import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { map, tap } from "rxjs/operators";
import { Client } from "src/app/shared/model/client";
import { ClientService } from "src/app/shared/service/client/client.service";
import { FormAjouterClient } from "../../services/form-ajouter-client.service";
import { PaysDIProvider } from "src/app/contexts/pays/_config/configDI.provider";
import { SearchPays } from "src/app/contexts/pays/usecases/search-pays/search-pays";
import { Pays } from "src/app/contexts/pays/domain/pays/pays";

@Component({
  selector: "app-client-edition",
  templateUrl: "./client-edition.component.html",
  styleUrls: [],
  providers: [PaysDIProvider.searchPays],
})
export class ClientEditionComponent implements OnInit {
  client: Client;
  loader: boolean = true;
  formAjoutClient: UntypedFormGroup;
  constructor(
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private formAjouterClient: FormAjouterClient
  ) {}

  ngOnInit(): void {
    const uuidClient = this.activatedRoute.snapshot.params.uuid;

    this.#initClient(uuidClient).subscribe();
  }

  #initClient = (uuid: string) => {
    return this.clientService.getClient(uuid).pipe(
      tap(() => (this.loader = true)),
      map((client: Client) => (this.client = client)),
      map(
        (client) =>
          (this.formAjoutClient = this.formAjouterClient.execute(client.uuid))
      ),
      tap(() => (this.loader = false))
    );
  };

  setClient = (event: any) => {};

  ajoutClient = (event: any) => {};
}
