import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Laposte } from "../../model/laposte";

@Injectable({
  providedIn: "root",
})
export class LaposteService {
  constructor(private http: HttpClient, handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  getVilleByCodePostal(codePostal: string) {
    return this.http
      .get<Laposte>(`https://geo.api.gouv.fr/communes?codePostal=${codePostal}`)
      .pipe();
  }
}
