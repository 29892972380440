import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";
import { ClientService } from "../service/client/client.service";
import { MessageService } from "primeng/api";
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
    public dialogRef: MatDialog,
    private clientService: ClientService,
    private messageService: MessageService
  ) {}
  openToaster(severity: string, summary: string) {
    return this.messageService.add({ severity, summary });
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.auth.token;
    const authReq = !token
      ? req
      : req.clone({
          headers: req.headers
            .set("Authorization", `Bearer ${token}`)
            .set("Content-Type", "application/json"),
        });

    return next.handle(authReq).pipe(
      tap(
        () => {},
        (httpError: HttpErrorResponse) => {
          this.clientService.loader$.next(false);
          if (httpError.status === 404 && httpError.error.code === "20001") {
            return;
          }
          if (httpError.status === 401) {
            this.dialogRef.closeAll();
            this.auth.logout().subscribe();
            return this.openToaster("error", "Session expirée");
          }
          return this.openToaster("error", "une erreur est survenue.");
        }
      )
    );
  }
}
