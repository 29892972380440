<div class="bloc">
  <div class="bloc-left">
    <div class="bloc-left-label">
      {{ "clientHisto.clientHistoDialog.historiqueClient" | translate }}
    </div>
    <app-client-histo-filtres [form]="form"> </app-client-histo-filtres>
  </div>
  <div class="bloc-right">
    <div class="close-dialog">
      <i class="pi pi-times" (click)="dialogRef.close()"></i>
    </div>

    <div class="mb-3">
      <h2>
        {{ "clientHisto.clientHistoDialog.client" | translate }} -
        {{ client.nom }} {{ client.prenom }}
      </h2>
      <small>
        {{ "clientHisto.clientHistoDialog.trouve" | translate }}
        <span class="font-weight-bold">{{ totalElement }}</span>
        {{ "clientHisto.clientHistoDialog.resultats" | translate }}</small
      >
    </div>

    <div
      *ngIf="loader"
      class="d-flex align-items-center justify-content-center"
    >
      <i class="pi pi-spin pi-spinner"></i>
    </div>
    <div #clientHistoList class="scroll-theme">
      <div>
        <app-client-histo-list
          [histoClient]="histoClient"
          *ngIf="histoClient.length"
        >
        </app-client-histo-list>
      </div>
    </div>
  </div>
</div>
