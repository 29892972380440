<div class="pt-2 pb-2" class="pt-2 pb-2">
  <h3>{{ title }}</h3>
</div>
<div *ngFor="let contact of contacts; let indexOfelement = index" class="ms-2">
  <p class="font-weight-bold">{{ subtitle }} {{ indexOfelement + 1 }}</p>
  <div class="ms-2" *ngIf="histoInit">
    <p
      *ngFor="let contactItem of contact | keyvalue"
      [class.d-none]="contactItem.key | clientHistoriqueFiltre"
      [ngClass]="
        contactItem | clientHistoriqueModification : histoInit[indexOfelement]
      "
    >
      {{ contactItem.key }} : {{ contactItem.value }}
    </p>
  </div>
  <div class="ms-2" *ngIf="!histoInit">
    <p
      *ngFor="let contactItem of contact | keyvalue"
      [class.d-none]="contactItem.key | clientHistoriqueFiltre"
    >
      {{ contactItem.key }} : {{ contactItem.value }}
    </p>
  </div>
</div>
