<form [formGroup]="form" *ngIf="form">
  <div class="calendarFormControl">
    <label>{{ label }}</label>
    <p-calendar
      formControlName="date"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [defaultDate]="defaultDate"
      [selectionMode]="selectionMode"
      [dateFormat]="dateFormat"
      hideOnDateTimeSelect="true"
      appendTo="body"
      (onSelect)="onSelect()"
      (onBlur)="onSelect()"
      firstDayOfWeek="1"
    ></p-calendar>
  </div>
</form>
