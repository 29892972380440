import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ClientEditionComponent } from "./clients/pages/client-edition/client-edition.component";

import { ClientsComponent } from "./clients/pages/clients/clients.component";
import { HomeComponent } from "./home/home.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { SessionExpireComponent } from "./session-expire/session-expire.component";
import { AuthGuardService as AuthGuard } from "./shared/auth/auth-guard.service";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },
  { path: "notFound", component: NotFoundComponent },
  { path: "sessionExpire", component: SessionExpireComponent },
  {
    path: "clients",
    canActivate: [AuthGuard],
    children: [
      {
        path: "",
        component: ClientsComponent,
      },
      {
        path: ":uuid",
        children: [{ path: "edition", component: ClientEditionComponent }],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
