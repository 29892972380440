import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Adresse } from "../../model/adresse";
import { Client } from "../../model/client";
import { Contact } from "../../model/contact";
import { HAL } from "../../model/hal";
import { HistoClient } from "../../model/histoClient";
import { Offre } from "../../model/offre";

import { toUrlWithParams } from "../../variables-globale/common/common";

import { PaysDIProvider } from "src/app/contexts/pays/_config/configDI.provider";
import { Pays } from "src/app/contexts/pays/domain/pays/pays";
import { PaysSnapshot } from "src/app/contexts/pays/domain/pays/pays.snapshot";
import { SearchPays } from "src/app/contexts/pays/usecases/search-pays/search-pays";

@Injectable({
  providedIn: "root",
})
export class ClientService {
  searchText$: BehaviorSubject<string> = new BehaviorSubject("");
  loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  modalOpen: boolean = false;
  listClient: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>([]);
  offres: Offre[] = [];
  nextLink: string;
  client$: Subject<Client> = new Subject<Client>();
  clientQuery$: BehaviorSubject<string> = new BehaviorSubject("");
  pays: PaysSnapshot[];
  #searchPays: SearchPays = PaysDIProvider.searchPays.useFactory(this.http);
  licence: string;
  constructor(private http: HttpClient, private fb: UntypedFormBuilder) {}

  formSearchClient = this.fb.group({
    email: [""],
    nom: [""],
    prenom: [""],
    adresse: [""],
    telephone: [""],
    codePostal: [""],
    ville: [""],
    carteFidelite: [null],
  });

  getTelephoneDomicile(contacts: Contact[]) {
    const telephoneDomicile = this.getContactByTypeNomAndModeContact(
      contacts,
      "DOMICILE",
      "TELEPHONE"
    );
    if (telephoneDomicile) {
      return telephoneDomicile;
    }
    const telephoneOther = contacts.find(
      (contact) =>
        contact.contactType !== "MOBILE" && contact.modeContact === "TELEPHONE"
    );
    if (!telephoneOther) {
      return null;
    }
    telephoneOther.contactType = "DOMICILE";
    telephoneOther.contactTypeNom = "Domicile";
    return telephoneOther;
  }
  replaceAtClient(index: number, client: Client) {
    const listClient = this.listClient.getValue();
    return this.replaceArray(index, listClient, client);
  }

  replaceArray(index: number, items: any, item: any) {
    const arr = [...items];
    const newArray = [...items.slice(0, index), item, ...arr.slice(index + 1)];
    return newArray as any;
  }
  getContactByTypeNomAndModeContact(
    contacts: Contact[],
    contactType: string,
    modeContact: string
  ) {
    if (!contacts) {
      return;
    }
    const contactClient = contacts.find(
      (contact) =>
        contact.contactType === contactType &&
        contact.modeContact === modeContact
    );

    if (!contactClient || contactClient.nom === "") {
      return;
    }

    return contactClient;
  }

  searchClientIndex(client: Client) {
    const listClient = this.listClient.getValue();
    const clientIndex = listClient.findIndex((c) => c.uuid === client.uuid);
    return clientIndex;
  }

  deleteClient(client: Client) {
    const listClient = this.listClient.getValue();
    const clientIndex = this.searchClientIndex(client);
    if (clientIndex === -1) {
      return console.error("client index not found");
    }
    listClient.splice(clientIndex, 1);
    this.listClient.next(listClient);
  }

  setClient(client: Client) {
    const clientIndex = this.searchClientIndex(client);

    if (clientIndex === -1) {
      return console.error("client index not found");
    }
    const newListClient = this.replaceAtClient(clientIndex, client);

    this.listClient.next(newListClient);
  }

  getPointsFidelite(clientUuid: string): Observable<any> {
    return this.http
      .get<any>(`${environment.apiUrl}/api/client/${clientUuid}/fidelite/solde`)
      .pipe(
        map((response) => response),
        catchError((err) => throwError(() => err))
      );
  }

  searchClientInput(
    query: string,
    sort?: string,
    newBaseUrl?: string
  ): Observable<HttpResponse<Client[]>> {
    const baseUrl = newBaseUrl || "/api/clients-recherche?page=0";

    return this.http
      .get<Client[]>(
        `${environment.apiUrl}${baseUrl}&${query}&sort=nom,prenom,asc&numerosLicence=${this.licence}`,
        {
          observe: "response",
        }
      )
      .pipe(catchError((err) => throwError(err)));
  }

  getClients(query: string) {
    const baseUrl = "/api/clients-recherche?page=0";
    return new Promise<HttpResponse<Client[]>>((resolve, reject) => {
      this.http
        .get<Client[]>(
          `${environment.apiUrl}${baseUrl}&${query}&sort=nom,prenom,asc&numerosLicence=${this.licence}`,
          { observe: "response" }
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  postCarteFideliteKtb() {
    return "4007007007007";
  }
  postCarteFidelite(clientUuid: string) {
    return new Promise<Client>((resolve, reject) => {
      this.http
        .post<Client>(
          `${environment.apiUrl}/api/client/${clientUuid}/fidelite/carte`,
          {}
        )
        .subscribe(
          (response: Client) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  postClient(body: string) {
    return new Promise<Client>((resolve, reject) => {
      this.http
        .post<Client>(`${environment.apiUrl}/api/client`, body)
        .subscribe(
          (response: Client) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  putClient(client: Client) {
    const clientJson = JSON.stringify(client);
    return new Promise<Client>((resolve, reject) => {
      this.http
        .put<Client>(
          `${environment.apiUrl}/api/client/${client.uuid}`,
          clientJson
        )
        .subscribe(
          (response: Client) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  putClientFusion(body: string) {
    return new Promise<Client>((resolve, reject) => {
      this.http
        .put<Client>(`${environment.apiUrl}/api/clients-fusion`, body)
        .subscribe(
          (response: Client) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  removeClient(url: string, uuid: string) {
    return new Promise((resolve, reject) => {
      this.http.delete(`${environment.apiUrl}${url}${uuid}`).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getClient = (uuid: string): Observable<Client> => {
    return this.http.get<Client>(`${environment.apiUrl}/api/client/${uuid}`);
  };

  searchHistoClient = (
    uuid: string,
    params = null
  ): Observable<HAL<HistoClient[]>> => {
    const url = `${environment.apiUrl}/api/yvidya/v2/entites/${this.licence}/client/${uuid}/historique`;
    const urlWithParams = toUrlWithParams(url, params);
    return this.http.get<HAL<HistoClient[]>>(urlWithParams);
  };
  getRgpdCourrier = (typeEnvoiCourrier: string | undefined) => {
    return typeEnvoiCourrier &&
      typeEnvoiCourrier.search("TECHNIQUE_COMMERCIAL") !== -1
      ? true
      : false;
  };

  getRgpdSms = (typeEnvoiSms: string | undefined) => {
    return typeEnvoiSms && typeEnvoiSms.search("TECHNIQUE_COMMERCIAL") !== -1
      ? true
      : false;
  };

  getRgpdEmail = (emailRgpd: string | undefined) => {
    return emailRgpd && emailRgpd.search("TECHNIQUE_COMMERCIAL") !== -1
      ? true
      : false;
  };

  getClientKtb(uuid: string) {
    return this.http.get<Client>(
      `${environment.apiUrl}/api/ktb/v1/client/${uuid}`
    );
  }

  removeAdresse(id: number) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(`${environment.apiUrl}/api/client-adresse/${id}`, {
          observe: "response",
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  removeContact(id: number) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(`${environment.apiUrl}/api/client-contact/${id}`, {
          observe: "response",
        })
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  addContact(body: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.apiUrl}/api/client-contact`, body)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  putContact(body: string) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiUrl}/api/client-contact`, body).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  postContact(body: string) {
    return new Promise<Contact>((resolve, reject) => {
      this.http
        .post<Contact>(`${environment.apiUrl}/api/client-contact`, body)
        .subscribe(
          (response: Contact) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  postAdresse(body: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.apiUrl}/api/client-adresse`, body)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  addAdresse(body: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${environment.apiUrl}/api/client-adresse`, body)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  putAdresse(body: string) {
    return new Promise((resolve, reject) => {
      this.http.put(`${environment.apiUrl}/api/client-adresse`, body).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getAllAdresses(uuid: string) {
    return new Promise<Adresse[]>((resolve, reject) => {
      this.http
        .get<Adresse[]>(
          `${environment.apiUrl}/api/client-adresses-par-client/${uuid}`
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getAllContacts(uuid: string) {
    return new Promise<Contact[]>((resolve, reject) => {
      this.http
        .get<Contact[]>(
          `${environment.apiUrl}/api/client-contacts-par-client/${uuid}`
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getPays = (): Observable<PaysSnapshot[]> => {
    return this.#searchPays.execute().pipe(
      map((result: Pays[]) => {
        const paysSort = result.sort((a, b) =>
          a.snapshot().nom < b.snapshot().nom
            ? -1
            : a.snapshot().nom > b.snapshot().nom
            ? 1
            : 0
        );
        return paysSort;
      }),
      map((pays: Pays[]) => (this.pays = pays.map((p) => p.snapshot())))
    );
  };

  getOffresClient(clientUuid: string) {
    return this.http.get<Offre[]>(
      `${environment.apiUrl}/api/ktb/v1/client/${clientUuid}/offres`
    );
  }

  removeOffresClient(clientUuid: string, codeOffre: string) {
    return new Promise<Offre[]>((resolve, reject) => {
      this.http
        .delete<Offre[]>(
          `${environment.apiUrl}/api/ktb/v1/client/${clientUuid}/offre/${codeOffre}`
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
