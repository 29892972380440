<div
  [ngClass]="{
    'move-table': moveTable
  }"
  class="h-100 flex-grow-1 client-list"
>
  <p-table
    scrollHeight="flex"
    [loading]="loader"
    [scrollable]="true"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [rowHover]="true"
    [value]="listClient"
    [columns]="cols"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>{{ "formColumn.nom" | translate }}</th>
        <th>{{ "formColumn.prenom" | translate }}</th>
        <th>
          {{ "formColumn.carteFidelite" | translate }}
        </th>
        <th>{{ "formColumn.email" | translate }}</th>
        <th>{{ "formColumn.telephoneMobile" | translate }}</th>
        <th>{{ "formColumn.adresse" | translate }}</th>
        <th>{{ "formColumn.ville" | translate }}</th>
        <th>{{ "formColumn.codePostal" | translate }}</th>

        <th style="width: 0rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-client>
      <tr
        *ngIf="
          (nbClientSearch > 0 && !loader) ||
          (nbClientSearch > 0 && infiniteScroll)
        "
        (dblclick)="getClient(client)"
        [ngClass]="clientActive(client)"
        (click)="setClient(client)"
      >
        <td>{{ client.nom }}</td>
        <td>{{ client.prenom }}</td>
        <td>
          <div *ngIf="client.carteFidelite">{{ client.carteFidelite }}</div>
        </td>
        <td>
          <div class="d-flex align-items-center">
            <i
              *ngIf="
                client.contacts
                  | contactNpai
                    : { modeContact: 'EMAIL', contactTypeNom: 'Personnel' }
              "
              matTooltip="{{ 'clientFicheContact.npai' | translate }}"
              class="pi pi-exclamation-triangle me-2"
            ></i>
            {{
              client.contacts
                | contactFilter
                  : { modeContact: "EMAIL", contactTypeNom: "personnel" }
            }}
          </div>
        </td>

        <td>
          <div class="d-flex align-items-center">
            <i
              *ngIf="
                client.contacts
                  | contactNpai
                    : { modeContact: 'TELEPHONE', contactTypeNom: 'Mobile' }
              "
              matTooltip="{{ 'clientFicheContact.npai' | translate }}"
              class="pi pi-exclamation-triangle me-2"
            ></i>
            {{
              client.contacts
                | contactFilter
                  : { modeContact: "TELEPHONE", contactTypeNom: "mobile" }
            }}
          </div>
        </td>
        <td>
          <div class="d-flex align-items-center">
            <i
              *ngIf="client.adresses | ContactAdresseNpai"
              matTooltip="{{ 'clientFicheContact.npai' | translate }}"
              class="pi pi-exclamation-triangle me-2"
            ></i>
            {{ client.adresses | contactAdresseFilter : "libelle1" }}
          </div>
        </td>
        <td>{{ client.adresses | contactAdresseFilter : "ville" }}</td>
        <td>{{ client.adresses | contactAdresseFilter : "codePostal" }}</td>

        <td style="width: 0rem">
          <div class="edit-display">
            <div>
              <i
                class="pi pi-pencil"
                matTooltip="{{
                  'clientFicheContact.modifierClient' | translate
                }}"
                (click)="getClientDialog(client)"
              ></i>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr
        *ngIf="(!loader && nbClientSearch === 0) || clientQuery.length <= 0"
        class="mt-2"
        col
      >
        <td class="mt-2 text-center" colspan="8">
          {{ "clientList.aucunClientTrouve" | translate }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<button
  (click)="getClient(client)"
  hidden
  id="rechercheOffres"
  name="rechercheOffres"
></button>
<button
  (click)="callWindevWithoutClient()"
  hidden
  id="quitterApplication"
  name="quitterApplication"
></button>
