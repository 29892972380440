import { HttpResponse } from "@angular/common/http";
import { ApiResponse } from "./apiResponse";

export class HttpResponseToApiResponse<T> {
  public page: number = 0;
  public totalElements!: number;
  public size: number = 20;
  public params!: Object;
  public totalPages: number;
  public execute = (
    httpResponse: HttpResponse<T[]>,
    mappedBody?: any
  ): ApiResponse<any[]> => {
    const link = httpResponse.headers?.get("Link") || "";
    this.page = this.#getNextPage(link) || 0;
    const totalCount = httpResponse?.headers?.get("X-Total-Count") || "";
    this.totalElements = parseInt(totalCount, 10);

    return {
      body: mappedBody || httpResponse.body,
      page: {
        number: this.page,
        size: this.size,
        totalElements: this.totalElements,
        totalPages: this.totalPages,
      },
    };
  };

  #getNextPage(link: string): number | null {
    if (!link) {
      return null;
    }
    const links = link.split(",");
    if (!links.length) {
      return null;
    }

    const nextLink = links.find((link) => link.includes("next"));
    if (!nextLink) {
      return null;
    }
    const linkTab = nextLink.replace("<", "").replace(">", "").split(";");
    const page = this.#getParameterByName("page", linkTab[0]);
    return page;
  }

  #getParameterByName(name: string, url: string): number | null {
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return 0;
    const pageNumber = decodeURIComponent(results[2].replace(/\+/g, " "));
    return +pageNumber;
  }
}
