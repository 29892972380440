import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiResponse } from "src/app/core/utils/apiResponse";
import { HttpResponseToApiResponse } from "src/app/core/utils/httpResponseToApiResponse";

import { environment } from "src/environments/environment";
import { Pays } from "../../../domain/pays/pays";
import { SearchPaysParams } from "../../../usecases/search-pays/search-pays-params";

import { PhoneNumberUtil } from "google-libphonenumber";
import { YvidyaPaysDTO } from "./yvidya-pays.dto";
import { YvidyaPaysMapper } from "./yvidya-pays.mapper";
import { toUrlWithParams } from "src/app/shared/common/common";

export class YvidyaPaysLoader {
  #baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  search(params: SearchPaysParams): Observable<ApiResponse<Pays[]>> {
    const baseUrl = `${this.#baseUrl}/api/pays`;

    const urlWithParams = toUrlWithParams(baseUrl, params);
    return this.http
      .get<YvidyaPaysDTO[]>(urlWithParams, {
        observe: "response",
      })
      .pipe(
        map<HttpResponse<YvidyaPaysDTO[]>, ApiResponse<Pays[]>>((res) => {
          const pays = res.body?.map(YvidyaPaysMapper.mapToPays);

          const paysAvecFlagEmoji: Pays[] = this.#getUrlFlagsEmojis(pays || []);

          const paysAvecIndicatifTelephonique: Pays[] =
            this.#getIndicatifTelephonique(paysAvecFlagEmoji);

          return new HttpResponseToApiResponse<YvidyaPaysDTO>().execute(
            res,
            paysAvecIndicatifTelephonique
          );
        })
      );
  }

  #getIndicatifTelephonique = (pays: Pays[]): Pays[] => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const paysAvecIndicatifTel: Pays[] = pays.map((pays) => {
      const indicatifTelephonique: number = phoneUtil.getCountryCodeForRegion(
        pays.snapshot().iso2
      );
      return new Pays({
        ...pays.snapshot(),
        indicatifTelephonique,
      });
    });
    return paysAvecIndicatifTel;
  };

  #getUrlFlagsEmojis = (pays: Pays[]): Pays[] => {
    const paysWithFlagEmoji: Pays[] = pays.map((pays) => {
      const urlFlagEmoji = this.#getUrlFlagEmoji(pays.snapshot().iso2);

      return new Pays({
        ...pays.snapshot(),
        urlFlagEmoji,
      });
    });

    return paysWithFlagEmoji;
  };

  #getUrlFlagEmoji = (iso2: string): string => {
    return `https://flagcdn.com/w20/${iso2.toLowerCase()}.png`;
  };
}
