import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import { MessageService } from "primeng/api";
import { BehaviorSubject, Subject, of, throwError } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";
import { PaysSnapshot } from "src/app/contexts/pays/domain/pays/pays.snapshot";
import { getMobilePaysISO2 } from "src/app/shared/common/common";
import { Client } from "src/app/shared/model/client";
import { ClientService } from "src/app/shared/service/client/client.service";
import { LaposteService } from "src/app/shared/service/laposte/laposte.service";

declare var formatStringWithoutDiacritics: any;
@Component({
  selector: "app-clients-form",
  templateUrl: "./clients-form.component.html",
  styleUrls: ["./clients-form.component.scss"],
})
export class ClientsFormComponent implements OnInit {
  @Output() send: EventEmitter<any> = new EventEmitter();
  @Output() update: EventEmitter<any> = new EventEmitter();
  @Output() submitForm: EventEmitter<any> = new EventEmitter();
  @Input() formAjoutClient: UntypedFormGroup;
  loader: boolean;
  @Input() client: Client;
  paysIso2: PaysSnapshot[];
  telephoneDom: any;
  soldeFidelite: string;
  telephoneMob: any;
  telephoneMobileContact: any;

  telephoneDomicileContact: any;
  closeDialog = true;
  adresseNpai = false;
  emailNpai = false;
  telephoneDomicileNpai = false;
  telephoneMobileNpai = false;
  villes: BehaviorSubject<any> = new BehaviorSubject(0);
  maxDate = new Date();
  errorDate = false;
  private destroy$: Subject<any> = new Subject();

  constructor(
    private messageService: MessageService,
    private clientService: ClientService,
    private laposteService: LaposteService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.paysIso2 = this.clientService.pays;
    if (this.client.uuid && this.client.carteFidelite) {
      this.getPointFidelite().subscribe();
    }

    this.#initClientForm(this.client);
    this.getCodePostal()?.subscribe();
  }

  public submit() {
    const closeDialog = this.closeDialog;
    this.submitForm.emit({
      closeDialog,
    });
  }

  getCodePostal() {
    return this.formAjoutClient.get("codePostal")?.valueChanges.pipe(
      debounceTime(500),
      filter((codePostal) => {
        if (codePostal.length > 4) {
          return true;
        }
        this.villes.next([]);
        return false;
      }),
      switchMap((codePostal: string) =>
        this.laposteService.getVilleByCodePostal(codePostal)
      ),
      map((responsePoste: any) => {
        if (!responsePoste) {
          return null;
        }

        if (responsePoste.length === 1) {
          return this.formAjoutClient.controls.ville.setValue(
            responsePoste[0].nom
          );
        }
        return this.villes.next(responsePoste);
      }),
      takeUntil(this.destroy$)
    );
  }

  getPointFidelite() {
    return this.clientService.getPointsFidelite(this.client.uuid).pipe(
      first(),
      map(
        (response) => (this.soldeFidelite = `${response.pointsFidelite} points`)
      ),
      catchError(() => {
        this.soldeFidelite = "Temporairement indisponible";
        return throwError(() => "l'api solde ktb ne répond pas");
      })
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setVilleWithOptionSelected(evt: MatAutocompleteSelectedEvent) {
    const ville = evt.option.value.nom.toUpperCase();
    const codePostal = evt.option.value.codesPostaux[0];
    this.formAjoutClient.controls.ville.setValue(ville);
    this.formAjoutClient.controls.codePostal.setValue(codePostal);
  }

  #initClientForm = (client: Client) => {
    const adresse = client.adresses ? client.adresses[0] : null;
    this.adresseNpai = adresse?.npai || false;

    this.telephoneDomicileContact = this.clientService.getTelephoneDomicile(
      client.contacts
    );

    this.telephoneDomicileNpai = this.telephoneDomicileContact?.statut || false;
    this.telephoneDom =
      (this.telephoneDomicileContact && this.telephoneDomicileContact.valeur) ||
      "";

    const email = client.contacts
      ? this.clientService.getContactByTypeNomAndModeContact(
          this.client.contacts,
          "PERSONNEL",
          "EMAIL"
        )
      : null;

    this.emailNpai = email?.statut || false;

    this.telephoneMobileContact = client.contacts
      ? this.clientService.getContactByTypeNomAndModeContact(
          this.client.contacts,
          "MOBILE",
          "TELEPHONE"
        )
      : null;

    this.telephoneMob =
      (this.telephoneMobileContact && this.telephoneMobileContact.valeur) || "";
    this.telephoneMobileNpai = this.telephoneMobileContact?.statut || false;

    const carteFidelite = client.uuid ? client.carteFidelite : "";

    const emailForm = client.uuid
      ? email && email.valeur.trim()
      : this.clientService.formSearchClient.controls.email.value;
    const nomForm =
      (client.uuid && client.nom) ||
      this.clientService.formSearchClient.controls.nom.value;
    const prenomForm =
      (client && client.prenom) ||
      this.clientService.formSearchClient.controls.prenom.value;
    const telephoneDomicileForm =
      (this.telephoneDomicileContact && this.telephoneDomicileContact.valeur) ||
      this.clientService.formSearchClient.controls.telephone.value;
    const codePostalForm =
      (adresse && adresse.codePostal) ||
      this.clientService.formSearchClient.controls.codePostal.value;
    const villeForm = this.client.uuid
      ? (adresse && adresse.ville) || ""
      : this.clientService.formSearchClient.controls.ville.value;

    const smsRgpd = this.client.uuid
      ? this.client.typeEnvoiSms
      : this.clientService.formSearchClient.controls.typeEnvoiSms?.value;
    const emailRgpd = this.client.uuid
      ? this.client.typeEnvoiEmail
      : this.clientService.formSearchClient.controls.typeEnvoiEmail?.value;
    const courrierRgpd = this.client.uuid
      ? this.client.typeEnvoiCourrier
      : this.clientService.formSearchClient.controls.typeEnvoiCourrier?.value;
    const form = {
      civiliteNom: (client.uuid && client.civiliteNom) || null,
      email: emailForm || null,
      nom: nomForm,
      prenom: prenomForm,
      libelle1: (adresse && adresse.libelle1) || "",
      libelle2: (adresse && adresse.libelle2) || "",
      libelle3: (adresse && adresse.libelle3) || "",
      libelle4: (adresse && adresse.libelle4) || "",
      telephoneMobile: this.telephoneMob || "",
      telephoneDomicile: telephoneDomicileForm,
      pays: (adresse && adresse.paysIso2) || "FR",
      dateAnniversaire:
        client && client.dateAnniversaire
          ? new Date(client.dateAnniversaire as string)
          : null,
      codePostal: codePostalForm,
      ville: villeForm,
      carteFidelite: carteFidelite,
      smsRgpd,
      emailRgpd,
      courrierRgpd,
      indicatifTelephoneMobile: this.telephoneMobileContact?.indicatif || 33,
      indicatifTelephoneDomicile:
        this.telephoneDomicileContact?.indicatif || 33,
      telephoneMobileISO2: getMobilePaysISO2(this.telephoneMobileContact),
      telephoneDomicileISO2: getMobilePaysISO2(this.telephoneDomicileContact),
    };
    this.formAjoutClient.patchValue(form);
  };

  handleChangeGenereCarteFidelite(data: any) {
    if (!data) {
      return;
    }
    this.formAjoutClient.controls.genereCarteFidelite.markAsDirty();
    this.formAjoutClient.controls.genereCarteFidelite.patchValue(
      data.carteFidelite
    );
  }

  codePostalMaxSize($event: any) {
    const cp = this.formAjoutClient.controls.codePostal.value;
    if (cp && cp.length > 5) {
      this.formAjoutClient.controls.codePostal.setValue(cp);
      return false;
    }
    return true;
  }

  getClientKtb() {
    return this.clientService
      .getClientKtb(this.client.uuid)
      .pipe(
        first(),
        map((client: Client) => {
          this.closeDialog = false;
          this.client = client;
          this.#initClientForm(client);
          return this.client;
        }),
        catchError((err) => {
          this.openToaster("error", "Impossible de récupérer la fiche client");
          return throwError(() => err);
        }),
        tap(() => this.submit()),
        tap(() =>
          this.openToaster(
            "success",
            "La fiche client a été mise à jour avec succès"
          )
        ),
        switchMap((client: Client) =>
          client.carteFidelite ? this.getPointFidelite() : of(true)
        ),
        finalize(() => (this.closeDialog = true))
      )
      .subscribe();
  }

  setDate = (data: any) => {
    this.errorDate = false;
    const dateAnniversaire =
      data.srcElement.value ||
      this.formAjoutClient.controls.dateAnniversaire.value;

    if (!dateAnniversaire) {
      return null;
    }

    const countSlash = (dateAnniversaire.match(/\//g) || []).length as number;
    if (
      dateAnniversaire.length === 8 &&
      (countSlash === 0 || countSlash === 2)
    ) {
      const switchDate = {
        2: this.formatDateAnniversaireYearTwoDigit,
        0: this.formatDateAnniversaireWithoutSlash,
      };
      return switchDate[countSlash](dateAnniversaire);
    }

    if (!this.formAjoutClient.controls.dateAnniversaire.value) {
      return (this.errorDate = true);
    }
    this.errorDate = false;
  };

  private formatDateAnniversaireYearTwoDigit = (dateAnniversaire: string) => {
    const dayDate = new Date();
    const anneeJour = dayDate.getFullYear().toString();
    const anneeTwoDigit = anneeJour.substring(2, 4);
    const anneeAnniversaire = dateAnniversaire.substring(6, 8);
    const jour = dateAnniversaire.substring(0, 2);
    const mois = dateAnniversaire.substring(3, 5);
    const newYear =
      anneeAnniversaire > anneeTwoDigit
        ? `19${anneeAnniversaire}`
        : `20${anneeAnniversaire}`;

    const newDate = [mois, jour, newYear].join("/");

    this.errorDate = false;
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    if (!date_regex.test(newDate)) {
      return (this.errorDate = true);
    }

    return this.formAjoutClient.controls.dateAnniversaire.patchValue(
      new Date(newDate)
    );
  };

  private formatDateAnniversaireWithoutSlash = (dateAnniversaire: string) => {
    const dayDate = new Date();
    const annee = dateAnniversaire.substring(4, 8);
    const jour = dateAnniversaire.substring(0, 2);
    const mois = dateAnniversaire.substring(2, 4);
    const newDate = [mois, jour, annee].join("/");

    const dateBirthday = new Date([annee, mois, jour].join("-"));
    if (dateBirthday.getTime() > dayDate.getTime()) {
      return (this.errorDate = true);
    }
    return this.formAjoutClient.controls.dateAnniversaire.patchValue(
      new Date(newDate)
    );
  };

  openToaster(severity: string, summary: string) {
    return this.messageService.add({ severity, summary });
  }

  formatString(event: any, field: string) {
    if (!(event.target && event.target.value)) {
      return;
    }
    const valeur = event.target.value;
    const valeurFormate = formatStringWithoutDiacritics(valeur);
    this.formAjoutClient.controls[field].setValue(valeurFormate);
  }
}
