<div class="d-flex">
  <div class="position-relative ms-3 ms-xl-4">
    <input
      [(ngModel)]="clientQuery"
      (ngModelChange)="setClientQuery()"
      (blur)="formatQuerystring()"
      class="search-input"
      id="search-box"
      autocomplete="off"
      (keyup.enter)="search()"
      placeholder="{{ 'clientsRecherche.rechercheClient' | translate }}"
    />
    <i class="pi pi-chevron-down" (click)="handleChangeCustomSearch()"></i>
    <form
      class="form"
      [formGroup]="formSearchClient"
      [ngClass]="{ hide: !displayCustomSearch }"
      (ngSubmit)="customSearch()"
    >
      <div>
        <h5 class="d-flex align-items-center">
          <div class="pe-3 position-relative d-flex align-items-center">
            <div class="info-recherche me-1">
              <i class="pi pi-info" [matMenuTriggerFor]="menu"></i>
            </div>
            {{ "clientsRecherche.rechercheAvancee" | translate }}
          </div>
          <mat-menu #menu="matMenu">
            <div class="info-bulle">
              <h3>Infos recherche</h3>
              <p>
                Vous pouvez ajouter un caractère
                <span class="font-weight-bold">*</span>&nbsp;où
                <span class="font-weight-bold">%</span>&nbsp;dans votre saisie
                afin d'effectuer une recherche élargie.
              </p>
            </div>
          </mat-menu>
          <h6 class="pe-3 border-left font-weight-bold position-relative">
            {{ nbClient }}
          </h6>
          <div class="flex-grow-1 d-flex justify-content-end">
            <i
              class="pi pi-chevron-up"
              role="button"
              (click)="closeCustomSearch($event)"
            ></i>
          </div>
        </h5>
      </div>
      <div class="container form-body">
        <div class="row w-100">
          <div class="col-12 col-lg-6">
            <mat-form-field>
              <mat-label>{{ "formColumn.nom" | translate }}</mat-label>
              <input
                (blur)="formatString($event, 'nom')"
                type="text"
                matInput
                formControlName="nom"
                placeholder="{{ 'formColumn.nom' | translate }}"
              />
              <mat-error>{{ "errorMessage.nom" | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field>
              <mat-label>{{ "formColumn.prenom" | translate }}</mat-label>
              <input
                (blur)="formatString($event, 'prenom')"
                type="text"
                matInput
                formControlName="prenom"
                placeholder="{{ 'formColumn.prenom' | translate }}"
              />
              <mat-error>{{ "errorMessage.prenom" | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-2">
            <mat-form-field>
              <mat-label>{{ "formColumn.codePostal" | translate }}</mat-label>
              <input
                (keypress)="codePostalMaxSize()"
                type="text"
                matInput
                formControlName="codePostal"
                placeholder="{{ 'formColumn.codePostal' | translate }}"
              />
              <mat-error>{{ "errorMessage.codePostal" | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-4">
            <mat-form-field>
              <mat-label>{{ "formColumn.ville" | translate }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="ville"
                placeholder="{{ 'formColumn.ville' | translate }}"
              />
              <mat-error>{{ "errorMessage.ville" | translate }}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-lg-6">
            <mat-form-field>
              <mat-label>{{ "formColumn.telephone" | translate }}</mat-label>
              <input
                type="text"
                matInput
                (blur)="telephoneRegex($event)"
                (keypress)="telephoneRegex($event)"
                formControlName="telephone"
                placeholder="{{ 'formColumn.telephone' | translate }}"
              />
              <mat-error>{{ "errorMessage.telephone" | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field>
              <mat-label>{{ "formColumn.email" | translate }}</mat-label>
              <input
                type="email"
                matInput
                formControlName="email"
                placeholder="{{ 'formColumn.email' | translate }}"
              />
              <mat-error>{{ "errorMessage.email" | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field>
              <mat-label>{{
                "formColumn.carteFidelite" | translate
              }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="carteFidelite"
                placeholder="{{ 'formColumn.carteFidelite' | translate }}"
              />
              <mat-error>{{
                "errorMessage.carteFidelite" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end form-footer">
        <button type="submit" class="btn btn-secondary">
          {{ "clientsRecherche.rechercher" | translate }}
        </button>
      </div>
    </form>
  </div>
  <div>
    <button class="btn btn-primary btn-search" (click)="search()">
      <i class="pi pi-search"></i>
    </button>
  </div>
</div>
