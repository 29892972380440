<form [formGroup]="form" class="h-100 d-flex flex-column pe-3">
  <app-calendar-form-control
    [formControlName]="'dateRangeMinMax'"
    [selectionMode]="'range'"
    label="{{ 'clientHisto.clientHistoFiltres.dateMinMax' | translate }}"
  ></app-calendar-form-control>

  <div class="recherche-footer">
    <div class="reset" (click)="formReset()">
      {{ "clientHisto.clientHistoFiltres.viderFormulaire" | translate }}
    </div>
  </div>
</form>
