<div class="h-100 d-flex flex-column">
  <div class="mat-dialog-header d-flex">
    <i class="pi pi-exclamation-triangle me-3"></i>
    <h3 class="">Erreur KTB</h3>
  </div>
  <div class="p-3">Récupération des offres chez KTB impossible</div>

  <div class="mat-dialog-footer">
    <div>
      <button type="button" mat-dialog-close class="btn btn-transparent me-3">
        {{ "bouton.annuler" | translate }}
      </button>
      <button type="submit" class="btn btn-secondary" (click)="submit()">
        {{ "bouton.continuer" | translate }}
      </button>
    </div>
  </div>
</div>
