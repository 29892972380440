import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../../model/contact';


@Pipe({
  name: 'contactFilter',
  pure: false
})
export class ContactFilterPipe implements PipeTransform {

  transform(items: Contact[], filter: {modeContact : string, contactTypeNom : string}): any {

    if (!items || !filter) {
      return "";
    }
    const newContact = items.find(item => item.modeContact === filter.modeContact && item.contactTypeNom?.toLowerCase() === filter.contactTypeNom);
    if(!newContact){
      return "";
    }
    return newContact.valeur;
  }

}
