import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "clientHistoriqueFiltre",
  pure: true,
})
export class ClientHistoriqueFiltrePipe implements PipeTransform {
  keysToDisplay = [
    "nom",
    "prenom",
    "carteFidelite",
    "civiliteNom",
    "typeEnvoiCourrier",
    "typeEnvoiEmail",
    "typeEnvoiSms",
    "dateAnniversaire",
    "adresses",
    "contacts",
    "codePostal",
    "libelle1",
    "libelle2",
    "libelle3",
    "libelle4",
    "ville",
    "paysIso2",
    "modeContact",
    "indicatif",
    "valeur",
  ];
  transform(key: any): any {
    if (!key) {
      return true;
    }
    const searchKeysToDisplay = this.keysToDisplay.find(
      (keyToDisplay: string) => key === keyToDisplay
    );
    if (!searchKeysToDisplay) {
      return true;
    }
    return false;
  }
}
