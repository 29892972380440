<div class="telephone-component">
  <label class="label-tel">{{ label }}</label>
  <form [formGroup]="form" class="form-input-tel">
    <div class="d-flex">
      <p-dropdown
        [options]="pays"
        optionValue="iso2"
        [formControlName]="paysISO2FieldForm"
        filterBy="nom"
        showClear="false"
        appendTo="body"
        filter="true"
        class="selectFormControl"
      >
        <ng-template let-option pTemplate="item">
          <img [src]="option?.urlFlagEmoji" /> {{ option.nom }}
        </ng-template>
        <ng-template let-option pTemplate="selectedItem">
          <img [src]="option?.urlFlagEmoji" />
        </ng-template>
      </p-dropdown>

      <input
        type="text"
        pInputText
        [formControlName]="numeroTelFieldForm"
        [placeholder]="placeholder"
        class="input-valeur-tel"
      />
    </div>
  </form>
  <div
    class="fieldErrors"
    *ngIf="form?.get(numeroTelFieldForm)?.hasError('invalidPhoneNumber')"
  >
    <small class="p-error block"
      >Format téléphone invalide. (exemple: {{ placeholder }})</small
    >
  </div>
  <div
    class="fieldErrors"
    *ngIf="form?.get(numeroTelFieldForm)?.hasError('required')"
  >
    <small class="p-error block">Veuillez renseigner ce champs.</small>
  </div>
</div>
