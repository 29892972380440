import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageMap } from "@ngx-pwa/local-storage";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { BehaviorSubject, Observable, iif } from "rxjs";
import { catchError, finalize, first, map, switchMap } from "rxjs/operators";
import { Entite } from "../model/entite";
import { Permission } from "../model/permission";
import { ClientService } from "../service/client/client.service";
import { LoginService } from "../service/login/login.service";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  public token: string;
  public licence: string;
  public entite: Entite | null;

  private _loader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  readonly loader$: Observable<boolean> = this._loader$.asObservable();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private localStorage: StorageMap,
    private loginService: LoginService
  ) {}

  public isAuthenticated = (): Observable<boolean> => {
    const token = this.router.url.includes("token");
    const licence = this.router.url.includes("licence");

    if (token && licence) {
      return this.setTokenWithEntite();
    }
    return this.getToken();
  };

  public logout = (): Observable<any> => {
    return this.localStorage.clear().pipe(
      first(),
      map(this.clearValues),
      map(() => this.router.navigate(["/sessionExpire"])),
      map(() => false),
      finalize(() => this._loader$.next(false))
    );
  };

  public setLoader(value: boolean) {
    return this._loader$.next(value);
  }

  public checkTokenExpirationMiddleware = (token: string): boolean => {
    if (!token) {
      return false;
    }
    const decodeToken: JwtPayload = jwtDecode(token);
    if (decodeToken && decodeToken.exp && decodeToken.exp < Date.now() / 1000) {
      return false;
    }
    return true;
  };

  private getTokenWithEntite = (licence: string): Observable<any> => {
    return this.loginService
      .postEnrichToken(licence)
      .pipe(map((tokenWithEntite: any) => tokenWithEntite.id_token));
  };

  private getPermissions = (): Observable<boolean> => {
    return this.loginService.getPermissions().pipe(
      map((permissions: Permission[]) =>
        this.getPermission(permissions, this.licence)
      ),
      switchMap(this.setEntite)
    );
  };

  private getPermission = (
    permissions: Permission[],
    licence: string
  ): boolean => {
    const permission = permissions.findIndex((permission) => {
      const entite = permission.entites.find(
        (entite) => entite.numeroLicence === licence
      );
      if (!entite) {
        return null;
      }

      this.entite = entite;
      return entite;
    });

    if (permission === -1) {
      return false;
    }

    return true;
  };

  private clearValues = () => {
    this.token = "";
    this.licence = "";
    this.entite = null;
  };

  private setTokenWithEntite = (): Observable<boolean> => {
    const tokenQuery = this.route.snapshot.queryParams.token;
    const licenceQuery = this.route.snapshot.queryParams.licence;

    this.token = tokenQuery;
    const checkToken = this.checkTokenExpirationMiddleware(tokenQuery);

    return iif(() => checkToken, this.initToken(licenceQuery), this.logout());
  };

  private initToken = (licenceQuery: string): Observable<boolean> => {
    return this.getTokenWithEntite(licenceQuery).pipe(
      switchMap((token) => this.setToken(token)),
      switchMap(() => this.setLicence(licenceQuery)),
      switchMap(this.getPermissions),
      switchMap(this.clientService.getPays),
      catchError(this.logout),
      map(() => true),
      finalize(() => this._loader$.next(false))
    );
  };

  private getToken = (): Observable<boolean> => {
    return this.localStorage.get("token").pipe(
      map((token: any) => (this.token = token)),
      switchMap(this.getLicence),
      switchMap(this.getPermissions),
      switchMap(this.clientService.getPays),
      catchError(this.logout),
      map(() => true),
      finalize(() => this._loader$.next(false))
    );
  };

  private setEntite = (): Observable<any> => {
    return this.localStorage
      .set("entite", this.entite)
      .pipe((entite) => entite);
  };

  private setToken = (token: string): Observable<string> => {
    return this.localStorage
      .set("token", token)
      .pipe(map(() => (this.token = token)));
  };

  private setLicence = (licence: string): Observable<string> => {
    return this.localStorage
      .set("numeroLicence", licence)
      .pipe(map(() => (this.licence = licence)));
  };

  private getLicence = (): Observable<string> => {
    return this.localStorage
      .get("numeroLicence")
      .pipe(map((licence: any) => (this.licence = licence)));
  };
}
