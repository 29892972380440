import { Inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

import { Pays } from "../../domain/pays/pays";
import { PaysLoader } from "../../domain/pays/pays.loader";
import { PaysSnapshot } from "../../domain/pays/pays.snapshot";
import { SearchPaysParams } from "./search-pays-params";
import { cleanObject } from "src/app/shared/common/common";
import { YvidyaPaysLoader } from "../../adapters/infra/real/yvidya-pays.loader";

@Injectable({
  providedIn: "root",
})
export class SearchPays {
  constructor(@Inject(YvidyaPaysLoader) private paysSource: PaysLoader) {}

  execute = (searchPaysParams?: SearchPaysParams): Observable<Pays[]> => {
    const pays = this.#recupPaysDuLocalStorage();
    if (!pays.length) {
      const params = {
        sort: "nom,asc",
        size: 300,
        page: 0,
        ...searchPaysParams,
      };

      return this.paysSource.search(cleanObject(params)).pipe(
        map((res) => res.body),
        map(this.#ajoutPaysAuLocalStorage),
        map(this.#paysSort)
      );
    }

    return of(pays);
  };

  #paysSort = (pays: Pays[]) => {
    if (!pays) {
      return [];
    }
    const paysSort = pays.sort((a: Pays, b: Pays) =>
      a.snapshot().nom < b.snapshot().nom
        ? -1
        : a.snapshot().nom > b.snapshot().nom
        ? 1
        : 0
    );
    return paysSort;
  };

  #ajoutPaysAuLocalStorage = (pays: Pays[]): Pays[] => {
    localStorage.setItem(
      "pays",
      JSON.stringify(pays.map((pays) => pays.snapshot()))
    );
    return pays;
  };

  #recupPaysDuLocalStorage = (): Pays[] => {
    const pays = JSON.parse(localStorage.getItem("pays") as string);
    if (!pays) {
      return [];
    }
    return pays?.map((pays: PaysSnapshot) => new Pays({ ...pays }));
  };
}
