<ng-container *ngIf="clients">
  <div
    class="d-flex flex-column h-100 px-3 py-4 px-xl-5 py-xl-4"
    [ngClass]="{ move: moveTable }"
  >
    <div class="d-flex mb-3 align-items-center">
      <div class="flex-grow-1 d-flex align-items-center">
        <h1 class="me-2" [matTooltip]="labelEnvAndApiClientExterne">
          {{ "clients.listeClient" | translate }}
        </h1>
        <div class="position-relative ms-3 ps-2 ms-xl-4 pl-xl-3 border-left">
          <app-clients-recherche
            [nbClient]="nbClientSearch"
            (handleChangeMoveTable)="handleChangeMoveTable($event)"
            (searchClient)="searchClient($event)"
          ></app-clients-recherche>
        </div>
        <h2 class="position-relative">
          {{ nbClientSearch }}
        </h2>
      </div>
      <app-clients-creation-bouton
        (reloadClient)="reload($event)"
      ></app-clients-creation-bouton>
    </div>
    <div
      class="position-relative overflow-hidden flex-grow-1"
      [ngClass]="{ 'move-table': moveTable }"
    >
      <app-clients-list
        [client]="client"
        (handleChangeClient)="handleChangeClient($event)"
        [moveTable]="moveTable"
        class="h-100 d-flex flex-column"
        (handleChangeSortOrder)="handleChangeSortOrder($event)"
        [listClient]="clients"
        [loader]="loader"
        [infiniteScroll]="infiniteScroll"
        [nbClientSearch]="nbClientSearch"
      ></app-clients-list>
    </div>
  </div>
</ng-container>
<div *ngIf="!(listClient$ | async)" class="loader">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<div *ngIf="client && client.uuid" id="get-clients" hidden>
  {{ client | json }}
</div>
