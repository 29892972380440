import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { fromEvent, Observable, Subject } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-calendar-form-control",
  templateUrl: "./calendar-form-control.component.html",
  styleUrls: ["./calendar-form-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendarFormControlComponent),
      multi: true,
    },
  ],
})
export class CalendarFormControlComponent
  implements OnInit, ControlValueAccessor
{
  //! https://www.primefaces.org/primeng/calendar

  @Input() label: string;

  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;
  @Input() defaultDate: Date | null = null;

  @Input() selectionMode: string = "single";

  @Input() dateFormat: string = "dd/mm/yy";

  // passer le callback pour la soumission du formulaire avec 'enter'
  // exemple : <app-calendar-form-control (submit)="submit()">
  @Output() submit: EventEmitter<any> = new EventEmitter();

  public form: UntypedFormGroup = this.fb.group({
    date: [],
  });

  #touched: boolean = false;
  #focused: boolean = false;
  #destroy$ = new Subject<void>();

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.initKeyDown$().subscribe();
  }

  ngOnDestroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
  }

  public onFocus() {
    if (!!this.#focused) {
      return;
    }

    this.markAsTouched();
  }

  public onSelect = (): void => {
    this.onChange(this.date.value);
  };

  // Accesors
  public writeValue(date: Date) {
    return this.date.patchValue(date);
  }

  private onChange = (_date: Date | null) => {};

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(fn: any) {
    this.form.valueChanges.pipe(filter(() => this.#touched)).subscribe(fn);
  }

  public markAsTouched() {
    if (!this.#touched) {
      this.#touched = true;
    }
  }

  private initKeyDown$ = (): Observable<KeyboardEvent> => {
    return fromEvent(document, "keydown").pipe(
      filter(
        (event: any) => event.code === "Enter" || event.code === "NumpadEnter"
      ),
      map(this.handleEnterKeyDown),
      takeUntil(this.#destroy$)
    );
  };

  private handleEnterKeyDown = (event: KeyboardEvent): KeyboardEvent => {
    this.onChange(this.date.value);
    this.submit.emit();
    return event;
  };

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.date.disable() : this.date.enable();
  }

  private get date(): AbstractControl {
    return this.form.controls.date;
  }
}
