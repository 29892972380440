import { Injectable } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ClientService } from "src/app/shared/service/client/client.service";
import { carteFideliteValidator } from "src/app/shared/validator/validator-carte-fidelite";

@Injectable()
export class FormAjouterClient {
  constructor(
    private fb: UntypedFormBuilder,
    private clientService: ClientService
  ) {}
  #regexEmail =
    "^[A-Z0-9a-z.!#$%&'*+-/=?^_`{|}~]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}";
  execute(uuidClient?: string) {
    const form = this.fb.group({
      civiliteNom: "" || null,
      email: ["", Validators.pattern(this.#regexEmail)],
      nom: ["", Validators.required],
      prenom: ["", Validators.required],
      libelle1: "",
      libelle2: "",
      libelle3: "",
      libelle4: "",
      telephoneMobile: "",
      telephoneDomicile: "",
      pays: "FR",
      dateAnniversaire: null,
      codePostal: ["", Validators.required],
      ville: "",
      smsRgpd: false,
      emailRgpd: false,
      courrierRgpd: false,
      carteFidelite: [
        "",
        [],
        [carteFideliteValidator(this.clientService, uuidClient)],
      ],
      genereCarteFidelite: false,
      telephoneMobileISO2: [""],
      telephoneDomicileISO2: [""],
      indicatifTelephoneDomicile: [""],
      indicatifTelephoneMobile: [""],
    });

    return form;
  }
}
