import { Pipe, PipeTransform } from "@angular/core";
import { Contact } from "../../model/contact";
import { ClientService } from "../../service/client/client.service";

@Pipe({
  name: "contactNpai",
  pure: false,
})
export class ContactNpaiPipe implements PipeTransform {
  constructor(private clientService: ClientService) {}
  transform(
    contacts: Contact[],
    filter: { modeContact: string; contactTypeNom: string }
  ): any {
    if (!contacts) {
      return false;
    }
    const contact = this.clientService.getContactByTypeNomAndModeContact(
      contacts,
      filter.contactTypeNom.toUpperCase(),
      filter.modeContact
    );

    if (!contact) {
      return false;
    }
    return contact.statut ? false : true;
  }
}
