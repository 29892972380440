import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { StorageMap } from "@ngx-pwa/local-storage";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "primeng/api";
import { throwError } from "rxjs";
import { catchError, first, map } from "rxjs/operators";
import { Client } from "src/app/shared/model/client";
import { Offre } from "src/app/shared/model/offre";
import { ClientService } from "src/app/shared/service/client/client.service";
import { YDate } from "src/app/shared/utils/date";
import { ClientsCreationDialogComponent } from "../clients-creation-dialog/clients-creation-dialog.component";
import { ClientsOffresDialogErreurComponent } from "../clients-offres-dialog-erreur/clients-offres-dialog-erreur.component";
import { ClientsOffresDialogComponent } from "../clients-offres-dialog/clients-offres-dialog.component";

declare const wlExecute: any;
@Component({
  selector: "app-clients-list",
  templateUrl: "./clients-list.component.html",
  styleUrls: ["./clients-list.component.scss"],
})
@Injectable()
export class ClientsListComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    private localStorage: StorageMap,
    private messageService: MessageService,
    public dialogRef: MatDialog,
    public clientService: ClientService
  ) {}

  @Input() client: Client;
  @Input() infiniteScroll: boolean;
  @Input() moveTable: boolean;
  @Input() loader: boolean;
  @Input() listClient: any[];
  @Input() clientQuery: string;
  @Input() nbClientSearch: number;
  @Output() handleChangeSortOrder: EventEmitter<any> = new EventEmitter();
  @Output() handleChangeClient: EventEmitter<any> = new EventEmitter();
  numeroLicence: string;
  offresKtbError: boolean = false;
  versionWD: string;
  cols: any[];
  labelEnvAndApiClientExterne: string = "";

  ngOnInit() {
    this.localStorage.get("numeroLicence").subscribe((data) => {
      const numLicence = data;
      if (!numLicence) {
        return;
      }
      if (typeof numLicence === "string") {
        this.numeroLicence = numLicence;
      }
    });

    this.localStorage
      .get("versionWD")
      .subscribe((versionWD) => (this.versionWD = versionWD as string));
  }

  setClient = (client: Client) => {
    this.handleChangeClient.emit({ client });
  };

  clientActive = (client: Client) => {
    if (this.client && client.uuid === this.client.uuid) {
      return "is-active";
    }
    return "";
  };

  getOffresDialogErreur = () => {
    const dialogRefOffres = this.dialog.open(
      ClientsOffresDialogErreurComponent,
      {
        data: {},
      }
    );
    dialogRefOffres.afterClosed().subscribe((response) => {
      this.offresKtbError = false;
      if (!response) {
        return;
      }
      return this.callWindev(this.client);
    });
  };
  getOffresDialog = () => {
    const dialogRefOffres = this.dialog.open(ClientsOffresDialogComponent, {
      maxWidth: "80vw",
      minWidth: "50vw",
      data: {
        client: this.client,
        getOffres: this.getOfffres,
      },
    });
    dialogRefOffres.afterClosed().subscribe((offres: Offre[]) => {
      if (this.offresKtbError) {
        return this.getOffresDialogErreur();
      }
      if (!offres) {
        return;
      }
      this.clientService.modalOpen = false;
      this.client.offres = offres;
      this.clientService.client$.next(this.client);
      return this.callWindev(this.client);
    });
  };

  callWindev = (client: Client) => {
    console.log("--------VERSION--------- : ", this.versionWD);
    this.dialog.closeAll();
    return wlExecute(client, this.clientService.modalOpen, this.versionWD);
  };

  callWindevWithoutClient = () => {
    return wlExecute(null, this.clientService.modalOpen, this.versionWD);
  };

  openToaster = (severity: string, summary: string) => {
    return this.messageService.add({ severity, summary });
  };

  getOfffres = (client: Client) => {
    return this.clientService.getOffresClient(client.uuid).pipe(
      first(),
      map((offres: Offre[]) => {
        if (!offres) {
          return this.callWindev(client);
        }
        const dayDate = new YDate().toShortFormat();
        const offreExpireOuUtilisee = offres.find(
          (offre) => dayDate && !offre.utilisee && offre.dateFin > dayDate
        );
        if (!offres.length || !offreExpireOuUtilisee) {
          return this.callWindev(client);
        }
        return offres;
      }),
      catchError((err) => {
        if (err.status === 404) {
          this.openToaster(
            "error",
            "Dans le cadre de la RGPD, le client a été archivé par KTB et la carte de fidélité réinitialisée."
          );
          this.callWindev(client);
          return throwError(() => err);
        }
        this.offresKtbError = true;

        return throwError(() => err);
      })
    );
  };

  getClient(client: Client) {
    if (client.uuid) {
      return this.getOffresDialog();
    }
    return this.callWindev(client);
  }

  getClientDialog = (client: Client) => {
    this.setClient(client);

    const dialogRef = this.dialog.open(ClientsCreationDialogComponent, {
      maxWidth: "80vw",
      data: {
        client,
        numeroLicence: this.numeroLicence,
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      this.clientService.modalOpen = false;
      if (!result) {
        return;
      }
      this.setClient(result.client);
    });
  };

  customSort = (event: any) => {
    const column = event.field;
    const mode = event.order === 1 ? "asc" : "desc";
    this.handleChangeSortOrder.emit({ sortOrder: `${column},${mode}` });
  };
}
