<div class="rgpd" [formGroup]="form" *ngIf="form">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ "clientsRgpd.contact" | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="rgpd-list">
          <input
            type="checkbox"
            formControlName="smsRgpd"
            id="smsRgpd"
            [ngClass]="{ disabled: smsDisabled }"
          />
        </div>
        <small>{{ "clientsRgpd.sms" | translate }}</small>
      </div>
      <div class="d-flex">
        <div class="rgpd-list">
          <input
            type="checkbox"
            formControlName="emailRgpd"
            id="emailRgpd"
            [ngClass]="{ disabled: emailDisabled }"
          />
        </div>
        <small>{{ "clientsRgpd.email" | translate }}</small>
      </div>
      <div class="d-flex">
        <div class="rgpd-list">
          <input
            type="checkbox"
            formControlName="courrierRgpd"
            id="courrierRgpd"
          />
        </div>
        <small>{{ "clientsRgpd.courrier" | translate }}</small>
      </div>
    </div>
  </mat-expansion-panel>
</div>
