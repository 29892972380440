import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "clientHistoriqueModification",
  pure: true,
})
export class ClientHistoriqueModificationPipe implements PipeTransform {
  transform(item: { key: number; value: string }, histoInit: any[]): any {
    if (!histoInit || !item) {
      return;
    }

    if (histoInit[item.key] !== item.value) {
      return "has-change";
    }
    return;
  }
}
