import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { StorageMap } from "@ngx-pwa/local-storage";
import { first, map } from "rxjs/operators";
import { AuthService } from "../shared/auth/auth.service";

@Component({
  selector: "app-session-expire",
  templateUrl: "./session-expire.component.html",
  styleUrls: ["./session-expire.component.scss"],
})
export class SessionExpireComponent implements OnInit {
  constructor(
    private localStorage: StorageMap,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authService.setLoader(false);
    this.localStorage
      .get("token")
      .pipe(
        first(),
        map((token: any) => {
          const checkToken =
            this.authService.checkTokenExpirationMiddleware(token);
          if (!checkToken) {
            return true;
          }
          return this.router.navigate([`/clients`]);
        })
      )
      .subscribe();
  }
}
