import { Component, Inject, Input, OnInit } from "@angular/core";
import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import {
  CountryCode,
  Examples,
  getCountryCallingCode,
  getExampleNumber,
  parsePhoneNumber,
} from "libphonenumber-js";
import examples from "libphonenumber-js/examples.mobile.json";
import { Observable, Subject } from "rxjs";
import { first, map, takeUntil, tap } from "rxjs/operators";
import { PaysDIProvider } from "src/app/contexts/pays/_config/configDI.provider";
import { Pays } from "src/app/contexts/pays/domain/pays/pays";
import { PaysSnapshot } from "src/app/contexts/pays/domain/pays/pays.snapshot";
import { SearchPays } from "src/app/contexts/pays/usecases/search-pays/search-pays";

@Component({
  selector: "app-telephone",
  templateUrl: "./telephone.component.html",
  providers: [PaysDIProvider.searchPays],
})
export class TelephoneComponent implements OnInit {
  @Input() form: any;
  //Désigner quel champs du formulaire contient le paysISO2 du télépone
  @Input() paysISO2FieldForm: string = "FR";
  //Désigner quel champ du formulaire contient le numero
  @Input() numeroTelFieldForm: string;
  //Désigner quel champ du formulaire contient l'indicatif pays
  @Input() indicatifPaysFieldForm: string;

  @Input() isRequired: boolean = false;
  @Input() label: string = "Téléphone";
  public pays: PaysSnapshot[];
  public placeholder: string = "numéro téléphone";

  #destroy$: Subject<any> = new Subject<any>();

  constructor(
    @Inject("SearchPays")
    private searchPays: SearchPays
  ) {}

  ngOnInit(): void {
    this.#initEssentielData().pipe(first()).subscribe();
    this.#listenerPaysChange().subscribe();
  }
  ngAfterViewInit() {
    const querySelectors = document.querySelectorAll(
      ".p-dropdown-label"
    ) as NodeListOf<HTMLElement>;
    if (!querySelectors) {
      return;
    }
    querySelectors.forEach(function (element) {
      element.tabIndex = -1;
    });
  }
  ngOnDestroy() {
    this.#destroy$.next(0);
    this.#destroy$.complete();
  }

  #initEssentielData = (): Observable<PaysSnapshot[]> => {
    const paysISO2: string = this.form.controls[this.paysISO2FieldForm].value;
    return this.#setPays().pipe(
      first(),
      tap(() => (this.placeholder = this.#getPlaceholder(paysISO2))),
      tap(() => this.#setValidatorsNumeroTel(paysISO2))
    );
  };

  #setPays = (): Observable<PaysSnapshot[]> => {
    return this.searchPays.execute().pipe(
      first(),
      map((pays: Pays[]) => pays.map((pays: Pays) => pays.snapshot())),
      map((pays: PaysSnapshot[]) =>
        this.#filterPaysNonConnuParLibphonenumber(pays)
      ),
      tap((pays: PaysSnapshot[]) => (this.pays = pays))
    );
  };

  #filterPaysNonConnuParLibphonenumber = (
    pays: PaysSnapshot[]
  ): PaysSnapshot[] => {
    const paysNonConnuParLibphonenumber: string[] = [
      "AQ",
      "AN",
      "BV",
      "CC",
      "CX",
      "GS",
      "HM",
      "TF",
      "UM",
      "XK",
    ];

    return pays.filter(
      (pays: PaysSnapshot) => !paysNonConnuParLibphonenumber.includes(pays.iso2)
    );
  };

  //Si l'indicatif tel change, on change le placeholder
  #listenerPaysChange = (): Observable<string> => {
    return this.form.controls[this.paysISO2FieldForm].valueChanges.pipe(
      takeUntil(this.#destroy$),
      tap((iso2: string) => (this.placeholder = this.#getPlaceholder(iso2))),
      tap((iso2: string) => this.#setIndicatif(iso2)),
      tap((iso2: string) => this.#setValidatorsNumeroTel(iso2))
    );
  };

  #setIndicatif = (iso2: string): void => {
    const indicatif: string = getCountryCallingCode(iso2 as CountryCode);
    const indicatifNumber: number = parseInt(indicatif.replace(/\D/g, ""), 10);
    return this.form.controls[this.indicatifPaysFieldForm].setValue(
      indicatifNumber,
      { emitEvent: false }
    );
  };

  #getPlaceholder = (paysIso2: string): string => {
    try {
      const exemplesNumeroTel: Examples = examples;
      const numExemple = getExampleNumber(
        paysIso2 as CountryCode,
        exemplesNumeroTel
      );

      const formatNational = numExemple?.formatNational() || "";
      if (
        paysIso2 === "FR" &&
        this.numeroTelFieldForm === "telephoneDomicile"
      ) {
        return formatNational?.replace(/06/g, "02");
      }
      return formatNational;
    } catch (e) {
      return "";
    }
  };

  #setValidatorsNumeroTel = (paysISO2: string): void => {
    const numeroTelControl: AbstractControl =
      this.form.controls[this.numeroTelFieldForm];

    if (!this.isRequired) {
      numeroTelControl.setValidators([this.#numeroTelValidator(paysISO2)]);
      return numeroTelControl.updateValueAndValidity();
    }
    numeroTelControl.setValidators([
      Validators.required,
      this.#numeroTelValidator(paysISO2),
    ]);

    return numeroTelControl.updateValueAndValidity();
  };

  #numeroTelValidator = (paysISO2: string): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const phoneNumber: string = control.value;
      if (!phoneNumber) {
        return null;
      }
      const isValidNumber: boolean = this.#checkNumeroTelephone(
        phoneNumber,
        paysISO2
      );

      return isValidNumber ? null : { invalidPhoneNumber: true };
    };
  };

  #checkNumeroTelephone = (numeroTel: string, paysISO2: string): boolean => {
    if (!numeroTel) {
      return false;
    }
    try {
      const phoneNumber = parsePhoneNumber(numeroTel, paysISO2 as CountryCode);

      const numeroTelNationnal = phoneNumber.formatNational();
      const phoneNumberNational = parsePhoneNumber(
        numeroTelNationnal,
        paysISO2 as CountryCode
      );

      return phoneNumberNational.isValid() ? true : false;
    } catch (e) {
      return false;
    }
  };
}
