export class YDate {
  constructor(public readonly date: Date = new Date()) {}

  // format Date vers "yyyy-mm-dd"
  public toShortFormat(): string | null {
    if (!this.date) {
      return null;
    }

    return [
      this.date.getFullYear(),
      this.padTo2Digits(this.date.getMonth() + 1),
      this.padTo2Digits(this.date.getDate()),
    ].join("-");
  }

  public toFrenchShortFormat(): string | null {
    if (!this.date) {
      return null;
    }

    return [
      this.padTo2Digits(this.date.getDate()),
      this.padTo2Digits(this.date.getMonth() + 1),
      this.date.getFullYear(),
    ].join("/");
  }

  public isBeforeToday(): boolean {
    if (!this.date) {
      return false;
    }

    const today = new Date();

    today.setHours(0, 0, 0, 0);

    return this.date < today;
  }

  public toFormatFileName(): string {
    // Obtenir les composants de la date (année, mois, jour, heure, minute)
    const year = this.date.getFullYear();
    const month = (this.date.getMonth() + 1).toString().padStart(2, "0"); // +1 car les mois commencent à 0
    const day = this.date.getDate().toString().padStart(2, "0");
    const hours = this.date.getHours().toString().padStart(2, "0");
    const minutes = this.date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}_${hours}h${minutes}`;
  }

  public toISOStringSansPrendreEnCompteLeFuseauHoraire(): string {
    const date = new Date(
      this.date.getTime() - this.date.getTimezoneOffset() * 60000
    );
    return date.toISOString();
  }

  // format Date vers Date a 23h59 pour gérer l'anti datage
  public toAntiDateFormat = (): Date | null => {
    if (!this.date) {
      return null;
    }

    this.date.setHours(23, 59, 0);
    return this.date;
  };

  // convertie "5" en "05"
  private padTo2Digits(num: number): string {
    return num.toString().padStart(2, "0");
  }
}
