import { PaysSnapshot } from "./pays.snapshot";

export class Pays {
  #id?: number | null;
  #nom: string;
  #iso2: string;
  #iso3?: string | null;
  #urlFlagEmoji?: string;
  #indicatifTelephonique?: number;

  constructor({
    id,
    nom,
    iso2,
    iso3,
    urlFlagEmoji,
    indicatifTelephonique,
  }: {
    id?: number | null;
    nom: string;
    iso2: string;
    iso3?: string | null;
    urlFlagEmoji?: string;
    indicatifTelephonique?: number;
  }) {
    this.#id = id;
    this.#nom = nom;
    this.#iso2 = iso2;
    this.#iso3 = iso3;
    this.#urlFlagEmoji = urlFlagEmoji;
    this.#indicatifTelephonique = indicatifTelephonique;
  }

  public snapshot = (): PaysSnapshot => ({
    id: this.#id,
    nom: this.#nom,
    iso2: this.#iso2,
    iso3: this.#iso3,
    urlFlagEmoji: this.#urlFlagEmoji,
    indicatifTelephonique: this.#indicatifTelephonique,
  });
}
