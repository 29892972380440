import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-client-histo-card",
  templateUrl: "./client-histo-card.component.html",
  styleUrls: ["./client-histo-card.component.scss"],
})
export class ClientHistoCardComponent implements OnInit {
  @Input() histo: any;
  @Input() histoInit!: any;
  @Input() title: string = "";
  constructor() {}

  ngOnInit(): void {}
}
