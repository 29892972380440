import { Injectable } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";

@Injectable()
export class FormSearchHistoClient {
  constructor(private fb: UntypedFormBuilder) {}

  execute() {
    const form = this.fb.group({
      dateRangeMinMax: [],
      page: 0,
      sort: "dateModification,desc",
    });

    return form;
  }
}
