<div class="loader-offres" *ngIf="loader">
  <app-loader
    [message]="'Récupération des offres en cours chez KTB'"
  ></app-loader>
</div>

<div *ngIf="!loader && offres.length" class="h-100 d-flex flex-column">
  <div class="mat-dialog-header d-flex">
    <h3 class="me-3">{{ "offres.titre" | translate }}</h3>
    <h6 class="pe-3 border-left font-weight-bold position-relative">
      {{ offres.length }}
    </h6>
  </div>
  <div class="p-3">
    <div
      class="list-item d-flex"
      *ngFor="let offre of offres"
      [ngClass]="{
        'is-active': dayDate && !offre.utilisee && offre.dateFin > dayDate,
        'is-current': isCurrent(offre)
      }"
    >
      <div class="list-item-checked">
        <div>{{ offre.valeur }}{{ offre.type === 1 ? "€" : "%" }}</div>
      </div>
      <div class="pt-2 pb-2 d-flex">
        <div class="flex-grow-1">
          <h3 class="text-uppercase">{{ offre.nom }}</h3>
          <p class="text-uppercase">{{ offre.description }}</p>
          <div class="d-flex align-items-center">
            <i class="pi pi-clock pe-2"></i>
            <small>{{ offre.dateDebut | date : "dd/MM/YYYY" }}</small>
            &nbsp;-&nbsp;
            <small>{{ offre.dateFin | date : "dd/MM/YYYY" }}</small>
          </div>
          <div>
            <span class="badge badge-primary" *ngIf="offre.immediat">{{
              "offres.immediat" | translate
            }}</span>
          </div>
        </div>
        <div
          class="d-flex align-items-center font-italic"
          *ngIf="offre.utilisee"
        >
          {{ "offres.utilisee" | translate }}
        </div>
        <div
          class="d-flex align-items-center font-italic"
          *ngIf="!offre.utilisee && dayDate && offre.dateFin < dayDate"
        >
          {{ "offres.expiree" | translate }}
        </div>
        <div
          class="d-flex align-items-center"
          *ngIf="!offre.utilisee && dayDate && offre.dateFin > dayDate"
        >
          <button
            (click)="getOffreValidationDialog(offre)"
            class="btn btn-primary"
          >
            {{ "offres.marquerUtilisee" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="mat-dialog-footer">
    <div>
      <button type="button" mat-dialog-close class="btn btn-transparent me-3">
        {{ "bouton.annuler" | translate }}
      </button>
      <button type="submit" class="btn btn-secondary" (click)="submit()">
        {{ "bouton.continuer" | translate }}
      </button>
    </div>
  </div>
</div>
